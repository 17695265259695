import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { customerRegistration } from '../classes/customerRegistration';
import { SocialLogin } from '../classes/socialLogin';
import { Address } from '../classes/customer';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductService } from './product.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Cart } from '../classes/cartGraphQl';
@Injectable({
  providedIn: 'root'
})
export class AccountService {


  private loginUserSubject: BehaviorSubject<any>;
  public loginUser: Observable<any>;

  
  constructor(
        public productService: ProductService,
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private apollo: Apollo,
        private location:Location
       ) {
       this.loginUserSubject = new BehaviorSubject<any>(localStorage.getItem('customerToken'));
       this.loginUser = this.loginUserSubject.asObservable();
  }

  public get loginUserValue(): any {
    return this.loginUserSubject.value;
}

  public login(email: string, password): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: gql`mutation($email: String!, $password: String!) {
                generateCustomerToken(email: $email, password: $password) {
                    token
                  }
              } 
            `,
        variables: {
          email: email,
          password: password
        }
      });
  }


  public registerCustomer(customer: customerRegistration): Observable<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(environment.product_base_url + environment.registerCustomerUrl, customer, { headers: headers });
  }

  public getCustomerDetails(): Observable<any> {    
    const token = localStorage.getItem('customerToken');
    let headers = new HttpHeaders();
    if (token) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }

    return this.http.get<any>(environment.api_host + `graphql?query={
           
      customer {
        firstname
        lastname
        email
        customer_quote_id
        is_subscribed
        default_shipping
        default_billing
        addresses {
          id
          firstname
          lastname
          street
          city
          region {
            region_code
            region
          }
          postcode
          country_id
          telephone
        }       
      }
    }
    `, { headers: headers });
  }

  public mergeCarts(source_cart_id: string, destination_cart_id: string): Observable<any> {
    source_cart_id = source_cart_id.split('|')[0];
    destination_cart_id = destination_cart_id.split('|')[0];

    return this.apollo
      .mutate<any>({
        mutation: gql`mutation($source_cart_id: String!, $destination_cart_id: String!) {
          mergeCarts(source_cart_id: $source_cart_id, destination_cart_id: $destination_cart_id) {
          items {
          id
          product {
          name
          sku
          }
          quantity
          }
          }
          }`,
        variables: {
          destination_cart_id: destination_cart_id,
          source_cart_id: source_cart_id
        }
      });
  }

  public async mergeUserCarts(token: any) {
    console.log('ssssssssssssssssss');
    
    localStorage.setItem("customerToken", token);
    this.loginUserSubject.next(token);
    this.productService.reCreateApolloAfterLoginLogOut();
    let guest_cart_id = localStorage["quoteId"];
    localStorage.removeItem('quoteId');
    let customer_cart = await this.productService.getOrCreateQuoteId();  
    localStorage.setItem('quoteId', customer_cart.data.createEmptyCart);      
    if (guest_cart_id) {
        this.mergeCarts(guest_cart_id, customer_cart.data.createEmptyCart).subscribe(response => {
      });
    }
    this.productService.cartItems.subscribe(response => {
      this.productService.setMiniCart(response.data.cart);
    });
    if (this.route.snapshot.queryParamMap.get('source')) {
      this.router.navigate(['/' + this.route.snapshot.queryParamMap.get('source')]);
    } else if (this.route.snapshot.queryParamMap.get('search')) {
      document.location.href = `nsearch?q=${this.route.snapshot.queryParamMap.get('search')}`;
    }
    else {
      this.location.back();
    }
  }

  /**
   * Calling fbmta.com
   * @param email 
   * @returns 
   */
  public memberSubscribeNewsletter(email: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer h227x8nbehc79x2qkoowo55vmkw87cod')
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json')
    .set('Accept-Language', 'en-us')

    let payload = {
      "email": email,
      "SiteGUID":"36985009-B47A-45E6-B920-6A09FB5B89D3",
      "ListID": "21474837043",
      "Action  ":"Subscribe",
      "InputSource": "W",
      "FirstName":"",
      "LastName":"",
      "StoreCode":"website"
    }
    return this.http.post(environment.product_base_url + environment.membersubscribe_newsletter_url, payload, { headers: headers });
  }

  public subscribeNewsletter(email: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');

    let payload = {
      "email": email
    }
    console.log(email);

    console.log(payload);
    return this.http.post(environment.product_base_url + environment.subscribe_newsletter_url, payload, { headers: headers });
  }
  public unSubscribeNewsletter(email: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');

    let payload = {
      "email": email
    }
    return this.http.post(environment.product_base_url + environment.unsubscribe_newsletter_url, payload, { headers: headers });
  }

  public socialLogin(socialLogin: SocialLogin): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(environment.product_base_url + environment.social_login_url, socialLogin, { headers: headers });
  }

  public getCustomerOrders(): Observable<any> {  
    const token = localStorage.getItem('customerToken');
    let headers = new HttpHeaders();
    if (token) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }
    return this.http.get<any>(environment.api_host + `graphql?query={
      customerOrders {
        items {      
          id
          increment_id
          created_at
          grand_total
          status
        }
      }
    }    
    `, { headers: headers });
  }

  public updateCustomerAddress(address: Address, defaultBilling: boolean, defaultShipping: boolean): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: gql`mutation ($id: Int!, $firstname: String!, $lastname: String!, $street: [String]!, 
          $city: String!, $region: String!, $region_code: String!, $region_id: Int!, $postcode: String!, $country_code: CountryCodeEnum!, $telephone: String!,
          $default_shipping: Boolean!, $default_billing: Boolean!) {
          updateCustomerAddress(id:$id, input: {
            firstname: $firstname
            lastname: $lastname
            street: $street
            city: $city
            region: {
              region: $region
              region_code: $region_code
              region_id: $region_id
            }
            country_id: $country_code
            telephone: $telephone
            postcode: $postcode
            default_shipping: $default_shipping
            default_billing: $default_billing
          }) {
            id
            firstname
            lastname
            street
            city
            region {
              region
            }
            postcode
            country_id
            telephone
            default_shipping
            default_billing
          }
        }`,
        variables: {
          id: address.id,
          firstname: address.firstname,
          lastname: address.lastname,
          street: address.street,
          city: address.city,
          region: address.region.region_code,
          region_code: address.region.region_code, 
          region_id: address.region.region_id,    
          postcode: address.postcode,
          telephone: address.telephone,
          country_code: address.country_id,
          default_shipping: defaultShipping,
          default_billing: defaultBilling
        }
      });
  }

  public addCustomerAddress(address: Address, defaultBilling: boolean, defaultShipping: boolean): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: gql`mutation ($firstname: String!, $lastname: String!, $street: [String]!, 
          $city: String!, $region: String!, $region_code: String!, $region_id: Int!, $postcode: String!, $country_code: CountryCodeEnum!, $telephone: String!,
          $default_shipping: Boolean!, $default_billing: Boolean!) {
            createCustomerAddress(input: {
            firstname: $firstname
            lastname: $lastname
            street: $street
            city: $city
            region: {
              region: $region
              region_code: $region_code
              region_id: $region_id
            }
            country_id: $country_code
            telephone: $telephone
            postcode: $postcode
            default_shipping: $default_shipping
            default_billing: $default_billing
          }) {
            id
            firstname
            lastname
            street
            city
            region {
              region
            }
            postcode
            country_id
            telephone
            default_shipping
            default_billing
          }
        }`,
        variables: {
          firstname: address.firstname,
          lastname: address.lastname,
          street: address.street,
          city: address.city,
          region: address.region.region_code,
          region_code: address.region.region_code, 
          region_id: address.region.region_id,
          postcode: address.postcode,
          telephone: address.telephone,
          country_code: address.country_id,
          default_shipping: defaultShipping,
          default_billing: defaultBilling
        }
      });
  }

  public deleteCustomerAddress(id: number): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: gql`mutation ($id: Int!) {
          deleteCustomerAddress(
            id: $id
          )
        }`,
        variables: {          
          id: id
        }
      });
  }

  logout() {
    localStorage.removeItem("customerToken");
    localStorage.removeItem("quoteId");
    let emptyCart: Cart = {}
    this.productService.setMiniCart(emptyCart);
    this.productService.reCreateApolloAfterLoginLogOut();
    this.router.navigate([``])
  }
  
  public changePasswoed(currentPassword: string, newPassword: string): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: gql`mutation($currentPassword: String!, $newPassword: String!) {
          changeCustomerPassword(
            currentPassword: $currentPassword
            newPassword: $newPassword
          )
          {
            firstname
            lastname
            email
          }
        }
        `,
        variables: {
          currentPassword: currentPassword,
          newPassword: newPassword
        }
      });
  }

  public updateAccount(customer: customerRegistration): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: gql`mutation($firstname: String!, $lastname: String!) {
          updateCustomer(
            input: {
              firstname: $firstname
              lastname: $lastname
            }
          ) {
            customer {
              firstname
              lastname
              email
            }
          }
        }
        `,
        variables: {
          firstname: customer.customer.firstname,
          lastname: customer.customer.lastname
        }
      });
  }

  public reWriteUrl(searchString: string): Observable<any> {
    let request: any = {};
    request.requestPath = searchString;
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');

    return this.http.post(environment.product_base_url + environment.url_rewrite_url, request, { headers: headers });
  }

  public writeLog(params: FormData): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken);

    return this.http.post(environment.product_base_url + environment.write_log_url, params, { headers: headers });
  }
}
