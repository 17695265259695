import { Component, OnInit } from '@angular/core';
import { NavService, MegaMenu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { ProductService } from '../../services/product.service';
import $ from 'jquery';
import { AccountService } from '../../services/account.service';
import { GeneralService } from '../../services/general.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

 
  megaMenuItems: MegaMenu;
  menuItems: any;
  constructor(private router: Router, public navServices: NavService, public productService: ProductService,
              public accountService: AccountService) {
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
      document.querySelector('body').classList.remove('sidebar-navmenu-open');
    });
  }
  public a ='';

  ngOnInit(): void {
      // this.initMegaMenu();
      this.tryonMenu();
      this.a.includes('&')
  }
  get compareItemsCount() {
    return JSON.parse(localStorage['compareItems'] || '[]').length;
  }
  tryonMenu(){
    this.productService.getProductListsJson().subscribe(response => {
      this.menuItems  = response?.menus;
      this.productService.tryOnData.next(response);
    })
  }
  initMegaMenu() {
    this.navServices.getMegaMenuDetails().subscribe((response: MegaMenu) => {
     /* response.children_data.forEach(res1 => {
        res1.active =  false;
        res1.children_data.forEach(res2 => {
          res2.active =  false;
          res2.children_data.forEach(res3 => {
            res3.active =  false;
          });
        });
      });*/
      this.megaMenuItems = response;
      // Commenting this out for future reference
      // console.log(this.megaMenuItems.data.categoryList);
    });
  }
  checkColum(data) {

    if (data.length === 2) {
        return {
          menuCol: 'col-md-4',
          imageCol: 'col-md-4'
        };
    }
    if (data.length === 3) {
      return {
        menuCol: 'col-md-3',
        imageCol: 'col-md-3'
      };
    }
    if (data.length === 4) {
      return {
        menuCol: 'col-md-2',
        imageCol: 'col-md-4'
      };
    }
    if (data.length === 5) {
      return {
        menuCol: 'col-md-2',
        imageCol: 'col-md-2'
      };
    }

    return {
      menuCol: 'col-md-2',
      imageCol: 'col-md-2'
    };
  }
  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
    (document.querySelector('body').classList.contains('sidebar-open')) ? document.querySelector('body').classList.remove('sidebar-open') : document.querySelector('body').classList.add('sidebar-open');
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(event) {
    $(event.target).toggleClass('active');
    $(event.target).next('.submenu').slideToggle();
    event.stopPropagation();
  }

closeMenu() {
    document.querySelector('.main-navbar').classList.add('hide');
    setTimeout(() => {
      document.querySelector('.main-navbar').classList.remove('hide');
    }, 300);
}

  setCategoryInfo(categoryImage, categoryDescription) {
    this.productService.categoryImage = categoryImage;
    this.productService.categoryDescription = categoryDescription;
  }

  get userLoggedIn() {
    if (localStorage['customerToken']) {
      return true;
    }
    return false;
  }
  logOut() {
    this.accountService.logout();
  }
  modifiyCategoryName(name) {
    
     let categoryName = name.replace(/[^a-zA-Z ]/g, "-");
     categoryName = categoryName.toLowerCase();
     categoryName =  categoryName.replace(/ +/g, '');
    
    return categoryName;
  }
}
