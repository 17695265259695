import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dpay',
  templateUrl: './dpay.component.html',
  styleUrls: ['./dpay.component.scss']
})
export class DpayComponent implements OnInit {

  constructor(
    public modalService: NgbModal,private toastrService: ToastrService
  ) { }
  @Input() iframeFrameData;
  ngOnInit(): void {
  }

  dismissAll() {
    this.toastrService.error('Your order was not submitted. Please choose another payment method or try again.');
    this.modalService.dismissAll();
  }
}