import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Title, Meta,} from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  public miniCartPopup = new BehaviorSubject(null);
  public isCheckoutPage = new BehaviorSubject(false);

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private toastrService: ToastrService
  ) {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      if (this.router.url === '/checkout') {
        this.isCheckoutPage.next(true);
      } else {
        this.isCheckoutPage.next(false);
      }
    });
  }


  displayMiniCartSuccessPopup(data) {
    this.miniCartPopup.next(data);
  }
  removeMiniCartSuccessPopup() {
    this.miniCartPopup.next(null);
  }

  generateRandomId(length) {
    const result = [];
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
    }
    return result.join('');
  }

  setDefaultMetaDetails() {
    this.titleService.setTitle(`Daniel's Jewelers Since 1948 | Daniel's Jewelers`);
    this.metaService.updateTag({ name: 'title', content: `Daniel's Jewelers Since 1948` });
    this.metaService.updateTag({ name: 'description', content: `Daniel's Jewelers Since 1948` });
  }

  setMetaDetails(title = null, description = null, suffix = false) {
    if (suffix) {
      this.titleService.setTitle(`${title} | Daniel's Jewelers `);
      this.metaService.updateTag({ name: 'title', content: `${title}` });
    }
    if (!suffix) {
      this.titleService.setTitle(`${title}`);
      this.metaService.updateTag({ name: 'title', content: `${title}` });
    }
    if (description) {
      this.metaService.updateTag({ name: 'description', content: description });
    }
  }
  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const allowed_types = [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'image/psd'
      ];
      if (!_.includes(allowed_types, fileInput.target.files[0].type) && !(fileInput.target.files[0].name.substring(fileInput.target.files[0].name.length - 4, fileInput.target.files[0].name.length) === '.msg')) {
        this.toastrService.error('Please upload files with the extension - jpg or png or psd only');
        return false;
      }
      if(_.includes(fileInput.target.files[0].name,'.jpeg')){
        this.toastrService.error('Please upload files with the extension - jpg or png or psd only');
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(fileInput.target.files[0]);
      let fileBase64Path:any;
      return Observable.create(observer => {
      reader.onload = (e: any) => {
        let blob = new Blob(fileInput.target.files, { type: fileInput.target.files[0].type });
        let url = window.URL.createObjectURL(blob);
        const encodedData=e.target.result.split(',');
        fileBase64Path = { base64String:encodedData[1],fileName:fileInput.target.files[0].name,fileType:fileInput.target.files[0].type};
        observer.next(fileBase64Path);
        observer.complete();
      };
    });
    }
  }
}
