<!--footer section -->
<footer [class]="class">
  <section class="footer-theme">
    <!-- <div class="main-wrapper" >
      <div class="row">
        <div class="col-sm-3 col-md-2" *ngFor="let item of footerData.items" >
          <div class="footer-content">
              <span  [innerHtml]="item.content | safe:'bindhtml'" ></span>
          </div>
        </div>
        <div class="col-md-4">
          <div *ngIf="newsletter" class="newsletter-subscription">
              <h4>Join Our Email List for Special Offers</h4>
              <form [formGroup]="subscriptionForm" (ngSubmit)="subscribeNewsletter()" class="subscribe-form mt-4" >
              <div class="form-group">
                <input type="email"  name="EMAIL" formControlName="subscriberEmail"  class="form-control" placeholder="Enter your email">
                <div *ngIf="subscriptionSubmitted && f.subscriberEmail.errors" class="invalid text-left">
                  <div *ngIf="f.subscriberEmail.errors.required">Email is required.</div>
                  <div *ngIf="f.subscriberEmail.errors.email">Email must be a valid email address.</div>
                </div>
              </div>
              <button [disabled]="subscriptionLoading" class="btn btn-solid btn-main small">
              <span *ngIf="subscriptionLoading" class="spinner-border spinner-border-sm mr-1"></span>
              subscribe
              </button>
              </form>
          </div>
        </div>
      </div>
    </div>   -->
    <div class="footer-social">
        <ul>
          <li>
            <a target="_blank" href="https://www.facebook.com/AUGMENTesTech"><img class="img-fluid" src="assets/images/footer_facebook.jpg"></a>
          </li>
          <li>
            <a target="_blank" href="https://www.instagram.com/AUGMENTesTech"><img class="img-fluid" src="assets/images/footer_instagram.jpg"></a>
          </li>
          <li>
             <a target="_blank" href="https://twitter.com/AUGMENTes_Tech"><img class="img-fluid" src="assets/images/footer_twitter.jpg"></a>
          </li>
        </ul>
    </div>
    <div class="footer-bottom">
        <div class="col-12 text-center">
          <div class="copyright text-center">
            <span class="text-center">© Copyright 2022 AUGMENTes, All Rights Reserved.</span>
            <p class="text-center">Powered By <a href="https://augmentes.com/" target="_blank">AUGMENTes.</a></p>
          </div>
          <div class="additional_footer_links">

          
              <ul class="">
                <li><a [routerLink]="['privacy']">Privacy Policy</a></li>
                <div class="border-right-line"></div>
                <li><a [routerLink]="['terms-of-use']" >Terms of use</a></li>
                <div class="border-right-line"></div>
                <li><a [routerLink]="['privacy', {source: 'California'}]">Your California Privacy Rights</a></li>
                <div class="border-right-line"></div>
                <li><a [routerLink]="['privacy', {source:'Disclosures'}]">Notice of Collection and Opt-out</a></li>
                <div class="border-right-line"></div>
                <li><a [routerLink]="['privacy', {source:'DNS'}]">Do Not Sell My Personal Information</a></li>
              </ul>
          </div>
        </div>
    </div>
  </section>
</footer>
<!--footer section end -->