import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  public isLoading = new BehaviorSubject(false);
  public dynamicCreatedLoader = new BehaviorSubject({id: null, create: null});
  constructor() { }

  startLoader() {
    this.isLoading.next(true);
  }
  stopLoader() {
    this.isLoading.next(false);
  }

  createLoader(id) {
    const from = {
      id,
      create: true
    };
    this.dynamicCreatedLoader.next(from);
  }
  removeLoader(id) {
    const from = {
      id,
      create: false
    };
    this.dynamicCreatedLoader.next(from);
  }
}
