import { Component, Input, OnInit, ElementRef, ViewChild, Output, EventEmitter} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'src/app/shared/services/product.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { VirtualTryOnCloseService } from './virtual-try-on.service'
@Component({
  selector: 'app-virtual-try-on',
  templateUrl: './virtual-try-on.component.html',
  styleUrls: ['./virtual-try-on.component.scss']
})
export class VirtualTryOnComponent implements OnInit {

  @Input() iframeSrc: SafeResourceUrl;
  @ViewChild('tryOnLoaderIframeRef',{static:false}) tryOnLoaderIframe: ElementRef;

        
  constructor(public modalService: NgbModal,public productService: ProductService, public sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService, private spinnerService: SpinnerService,
    private virtualTryOnCloseService: VirtualTryOnCloseService
    ) {
      this.spinner.show()
      this.getIframeData() 
   }
  
  ngOnInit(): void {
         
  }

  
  getIframeData(){
        // this.spinnerService.startLoader()
    this.productService.tryOnUrl.subscribe(response => {
      if(response){
        // this.spinner.hide()
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(response);
      }
    },error => {
     this.spinner.hide();
    // this.spinnerService.stopLoader()
    });
  }

  onLoad() {
    // Try this also if we wanted to test
    // const iframeReference = document.getElementById("try-on-loader-iframe") as HTMLInputElement;
    // console.log(iframeReference.src)
    console.log(this.tryOnLoaderIframe.nativeElement.src)
    if (this.tryOnLoaderIframe.nativeElement.src) {
      this.spinner.hide()
    }
  }

  dismissAll() {
    this.modalService.dismissAll();
    this.virtualTryOnCloseService.sendClickEvent();
  }
}
