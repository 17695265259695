<ng-template class="theme-modal" #orderSummary let-modal>
	<div class="modal-content-inner">
		<div class="modal-header">
			<img alt="logo" src="assets/images/danielslogo.jpeg">
			<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="goBack()">
			<span aria-hidden="true">&times;</span>
			</button>
		</div>
	 	 <div class="modal-body">	
			<ng-container *ngIf="cart">
				<div class="container-fluid order-details">
					<div class="d-none" id="hidden-title">
						<h2>Riddle</h2>
          </div>
          <div class="row">
            <div class="section-header">
              <h2>Order Details</h2>
              <hr role="tournament6">
            </div>
            <div class="col-12">
              <p>
                Order Summary
                <br>
                Below is a summary of your order outlining your product and payment selection. We recommend printing or saving a copy of this page for your records.
                <br>
                Date: {{ date | date: 'longDate' }}
              </p>
              <p>
                Riddle's Jewelry | 2707 Mt. Rushmore Road | Rapid City, SD 57703
              </p>
            </div>
            <div class="col-lg-7">
              <div class="details-holder">
                <div class="row header-row">
                  <div class="col-6">
                    <h5 class="summary-title">Product</h5>
                  </div>
                  <div class="col-2 col-sm-3 col-md-2">
                    <h5 class="summary-title d-none d-sm-block">Quantity</h5>
                    <h5 class="summary-title d-sm-none">Qty</h5>
                  </div>
                  <div class="col-4 col-sm-3 col-md-4">
                    <h5 class="summary-title d-none d-sm-block">Amount</h5>
                    <h5 class="summary-title d-sm-none">Amt</h5>
                  </div>
                </div>
                <ng-container *ngFor="let item of cart.items ; let i = index">
                  <div class="row row-padding">
                    <div class="col-6">
                      <div class="product-name">
                        {{ item.product.name }}
                      </div>
                    </div>
                    <div class="col-2 col-sm-3 col-md-2">
                      <div class="product-qty">
                        {{ item.quantity }}
                      </div>
                    </div>
                    <div class="col-4 col-sm-3 col-md-4">
                      <div class="product-price">
                        {{ item?.price  | currency:productService?.Currency.currency:'symbol' }}
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="order-price">
                  <div class="row row-padding">
                    <div class="col-6 col-md-8">
                      <div class="tag-bold"> Cart Sub Total</div>
                    </div>
                    <div class="col-6 col-md-4">
                      <div class="tag-bold price">{{ cart.prices.subtotal_excluding_tax.value | currency:productService?.Currency.currency:'symbol'}}</div>
                    </div>
                  </div>
                  <ng-container *ngIf="cart.shipping_addresses && cart.shipping_addresses[0] && cart.shipping_addresses[0].selected_shipping_method">
                    <div class="row row-padding">
                      <div class="col-6 col-md-8">
                        <div class="tag-bold">{{ cart.shipping_addresses[0].selected_shipping_method.carrier_title }}</div>
                      </div>
                      <div class="col-6 col-md-4">
                        <div class="tag-bold price">{{ cart.shipping_addresses[0].selected_shipping_method.amount.value | currency:productService?.Currency.currency:'symbol' }}</div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="cart.prices.discounts && cart.prices.discounts.amount.value != 0">
                    <div class="row row-padding">
                      <div class="col-6 col-md-8">
                        <div class="tag-bold">Discount</div>
                      </div>
                      <div class="col-6 col-md-4">
                        <div class="tag-bold price">-{{ cart.prices.discounts.amount.value | currency:productService?.Currency.currency:'symbol' }}</div>
                      </div>
                    </div>
                  </ng-container>
                  <div class="row row-padding">
                    <div class="col-6 col-md-8">
                      <div class="tag-bold">Tax</div>
                    </div>
                    <div class="col-6 col-md-4">
                      <div class="tag-bold price">
                        <ng-container *ngIf="cart.prices.applied_taxes.length>0">
                          {{ cart.prices.applied_taxes[0]?.amount.value | currency:productService?.Currency.currency:'symbol' }}
                        </ng-container>
                        <ng-container *ngIf="cart.prices.applied_taxes.length===0">
                            -
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="row row-padding">
                    <div class="col-6 col-md-8">
                      <div class="tag-bold">Order Total Amount</div>
                    </div>
                    <div class="col-6 col-md-4">
                      <div class="tag-bold price">{{ cart.prices.grand_total.value | currency:productService?.Currency.currency:'symbol'}}</div>
                    </div>
                  </div>

                  <div class="row row-padding border-0">
                    <div class="col-12 order-summary-content">
                      <div>
                        <p class="info">Account Number Ending In: <span>{{ wellsFargoPopupInfo.cardNumber }}</span></p>
                      </div>
                      <div>
                        <p class="info">Authorization: <span>{{ wellsFargoPopupInfo.authorization }}</span></p>
                      </div>
                      <div>
                        <p>Special Terms and APR Information</p>
                        <p>{{ wellsFargoPopupInfo.specialTerms }}</p>
                      </div>
                      <div>
                        <p>Electronic Signature</p>
                        <p>You agree that this transaction is under your Credit Card Agreement with Wells Fargo Bank, N.A. (the Bank"). You give the Bank a purchase-money security interest in goods purchased in this transaction.</p>
                      </div>                    
                      <div>
                        <p><input [(ngModel)]="wellfargoConsentChecked" type="checkbox" class="mr-2"> By checking this box, I acknowledge that this is my electronic signature.</p>
                        <div *ngIf="!wellfargoConsentChecked" class="invalid">Please check the checkbox above to sign electronically.</div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5 order-summary">
              <div class="details-holder">
                <div class="row header-row light">
                  <div class="col-12">
                    <h5 class="summary-title">Billing Address</h5>
                  </div>
                </div>
                <div class="row row-padding small">
                  <div class="col-12">
                    <ul class="summary-list">
                      <li>{{ cart.billing_address.firstname + ' ' + cart.billing_address.lastname }}</li>
                      <li>{{ cart.billing_address.street[0] }}</li>
                      <li>{{ cart.billing_address.street[1] }}</li>	
                      <li>{{ cart.billing_address.city }}, {{ cart.billing_address.region.label }}
                          , {{ cart.billing_address.postcode }}</li>
                      <li>Contact No. {{ cart.billing_address.telephone }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="details-holder">
                <div class="row header-row light">
                  <div class="col-12">
                    <h5 class="summary-title">Shipping Address</h5>
                  </div>
                </div>
                <div class="row row-padding small">
                  <div class="col-12">
                    <ul class="summary-list">
                      <li>{{ cart.shipping_addresses[0].firstname + ' ' + cart.shipping_addresses[0].lastname }}</li>
                      <li>{{ cart.shipping_addresses[0].street[0] }}</li>
                      <li>{{ cart.shipping_addresses[0].street[1] }}</li>
                      <li>{{ cart.shipping_addresses[0].street[1] }}</li>	
                      <li>{{ cart.shipping_addresses[0].city }}, {{ cart.shipping_addresses[0].region.label }}
                          , {{ cart.shipping_addresses[0].postcode }}</li>
                      <li>Contact No. {{ cart.shipping_addresses[0].telephone }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="details-holder">
                <div class="row header-row light">
                  <div class="col-12">
                    <h5 class="summary-title">Shipping Method</h5>
                  </div>
                </div>
                <div class="row row-padding small">
                  <div class="col-12">
                    <ul class="summary-list">
                      <li>{{ cart.shipping_addresses[0].selected_shipping_method.carrier_title }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <button class="btn btn-solid dark mr-2 mb-2" (click)="goBack()">Select another method of payment</button>
                <button class="btn btn-solid dark mr-2 mb-2" printSectionId="printOrderSummary" ngxPrint >Save/Print</button>
                <button class="btn btn-solid dark mr-2 mb-2" [disabled]="!wellfargoConsentChecked" (click)="placeOrder()">Place Order Now</button>
            </div>
          </div>
					<div id="printOrderSummary" class="row d-none">
							<div class="col-md-7">
                  <h3> &#8226; YOUR ORDER DETAILS</h3>
                  <div>
                    <p>
                      Order Summary
                      <br>
                      Below is a summary of your order outlining your product and payment selection. We recommend printing or saving a copy of this page for your records.
                      <br>
                      Date: {{ date | date: 'longDate' }}
                    </p>
                    <p>
                      Riddle's Jewelry | 2707 Mt. Rushmore Road | Rapid City, SD 57703
                    </p>
                  </div>
									<ng-container *ngFor="let item of cart.items ; let i = index">
											<div class="cart-view">
													<div>
															<div style="padding-top: 2px">No : {{i + 1}}</div>
													</div>
													<div class="product-name">
															{{ item.product.name }}
													</div>
													<div>
															<table>
																	<tr>
																			<td>Quantity&nbsp;</td>
																			<td>:&nbsp;</td>
																			<td>{{ item.quantity }}</td>
																	</tr>
																	<tr>
																			<td> Total&nbsp;</td>
																			<td>:&nbsp;</td>
																			<td>
																					<b>{{ item?.price  | currency:productService?.Currency.currency:'symbol' }}</b>
																			</td>
																	</tr>
															</table>
													</div>
											</div>
									</ng-container>
	
									<div>
											<br>
											<table class="table" style="border-bottom: 1px solid #dee2e6">
													<tr>
															<th colspan="3">
																	CART SUB-TOTAL
															</th>

															<th class="text-right">
																	{{ cart.prices.subtotal_excluding_tax.value | currency:productService?.Currency.currency:'symbol'}}
															</th>
													</tr>

														<ng-container
																	*ngIf="cart.shipping_addresses && cart.shipping_addresses[0] && cart.shipping_addresses[0].selected_shipping_method">
															<tr>
																	<th colspan="3">
																			{{ cart.shipping_addresses[0].selected_shipping_method.carrier_title }}
																	</th>
																	<th class="text-right">
																			{{ cart.shipping_addresses[0].selected_shipping_method.amount.value | currency:productService?.Currency.currency:'symbol' }}
																	</th>
															</tr>
													</ng-container>

													<ng-container *ngIf="cart.prices.discounts && cart.prices.discounts.amount.value != 0">
															<tr>
																	<th colspan="3">
																			DISCOUNT
																	</th>
																	<th class="text-right">
																			-{{ cart.prices.discounts.amount.value | currency:productService?.Currency.currency:'symbol' }}
																	</th>
															</tr>
													</ng-container>

													<ng-container>
															<tr>
																	<th colspan="3">
																			TAX
																	</th>
																	<th class="text-right">
																			<ng-container *ngIf="cart.prices.applied_taxes.length>0">
																					{{ cart.prices.applied_taxes[0]?.amount.value | currency:productService?.Currency.currency:'symbol' }}
																			</ng-container>
																			<ng-container *ngIf="cart.prices.applied_taxes.length===0">
																					-
																			</ng-container>
																	</th>
															</tr>
													</ng-container>

													<tr>
															<th colspan="3">
																	ORDER TOTAL AMOUNT
															</th>

															<th class="text-right">
																	<div class="total-amount">{{ cart.prices.grand_total.value | currency:productService?.Currency.currency:'symbol'}}</div>
															</th>
													</tr>
											</table>
											<br>
                      <br>
                      <div>
                        <p>
                          Account Number Ending In: {{ wellsFargoPopupInfo.cardNumber }}
                        </p>
                        <p>
                          Authorization: {{ wellsFargoPopupInfo.authorization }}
                        </p>
                        <p>
                          Special Terms and APR Information
                          <br>
                          {{ wellsFargoPopupInfo.specialTerms }}
                        </p>
                        <p>
                          Electronic Signature
                          <br>
                          You agree that this transaction is under your Credit Card Agreement with Wells Fargo Bank, N.A. (the Bank"). You give the Bank a purchase-money security interest in goods purchased in this transaction.
                          <br>                          
                        </p>
                      </div>
									</div>
	
	
							</div>
	
							<div class="col-6 col-md-5">												
									<hr>
									<div class="order-summary-address">
											<h4> &#8226; billing address</h4>
											<ul class="ul-li">
													<li>{{ cart.billing_address.firstname + ' ' + cart.billing_address.lastname }}</li>
													<li>{{ cart.billing_address.street[0] }}, </li>
													<li *ngIf="cart.billing_address.street[1]">{{ cart.billing_address.street[1] }}, </li>
													<li>{{ cart.billing_address.city }},  {{ cart.billing_address.region.label }},  {{ cart.billing_address.postcode }}</li>													
													<li>Contact No. {{ cart.billing_address.telephone }}</li>
											</ul>
									</div>
	
									<hr>
									<div class="order-summary-address">
											<h4> &#8226; shipping address</h4>
											<ul class="ul-li">
													<li>{{ cart.shipping_addresses[0].firstname + ' ' + cart.shipping_addresses[0].lastname }}</li>
													<li>{{ cart.shipping_addresses[0].street[0] }}, </li>
													<li *ngIf="cart.shipping_addresses[0].street[1]">{{ cart.shipping_addresses[0].street[1] }}</li>	
													<li>{{ cart.shipping_addresses[0].city }}, {{ cart.shipping_addresses[0].region.label }}, {{ cart.shipping_addresses[0].postcode }}
													</li>
													<li>Contact No. {{ cart.shipping_addresses[0].telephone }}</li>
											</ul>
									</div>
	
									<hr>
									<div>
											<h4> &#8226; Shipping method</h4>
											{{ cart.shipping_addresses[0].selected_shipping_method.carrier_title }}
									</div>
									<hr>
									<br>
							</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</ng-template>