import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Product } from "../../../classes/product";
import $ from 'jquery';
@Component({
  selector: 'app-size-modal',
  templateUrl: './size-modal.component.html',
  styleUrls: ['./size-modal.component.scss']
})
export class SizeModalComponent implements OnInit, OnDestroy  {
  
  @Input() product: Product;

  @ViewChild("sizeChart", { static: false }) SizeChart: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  public showFirstPage =  true;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal) { }

  ngOnInit(): void {
  }
  showHideRingSizeGuide() {
    this.showFirstPage = (this.showFirstPage) ? false : true;
    $('#ringsize_guide').scrollTop(0);
  }
  openModal() {
    this.modalOpen = true;
    $('.modal-content').scrollTop = 0;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.SizeChart, { 
        size: 'lg',
        ariaLabelledBy: 'size-modal',       
        scrollable: true,
        windowClass: 'SizeChart daniels-modal-dialog' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    this.showFirstPage =  true;
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
    this.showFirstPage =  true;
  }

}
