<ng-template class="theme-modal" #popupOffer let-modal>
	<div class="modal-content-inner">
	  <div class="modal-header">
	    <h5 class="modal-title" id="exampleModalLabel">Offer</h5>
	    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
	      <span aria-hidden="true">&times;</span>
	    </button>
	  </div>
	  <div [innerHTML]="content" class="modal-body">			   
		 </div>
	</div>
</ng-template>