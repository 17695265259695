import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

const routes: Routes = [
  {
    path: 'search',
    component: ElementsComponent,
    loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },

  { 
    path: 'pages',
    component: PagesComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  { path: 'orderDashboard', 
  loadChildren: () => import('./order-dashboard/order-dashboard.module').then(m => m.OrderDashboardModule) 
  },
  { 
    path: 'nsearch', 
    component: ElementsComponent,
    loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule) 
  },
  {
    path: '',
    component: ShopComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
  },
  {
    path: ':s1', 
    redirectTo: 'pages/reWriteUrl/:s1'   
  },
  {
    path: ':s1/:s2', 
    redirectTo: 'pages/reWriteUrl/:s1/:s2'   
  },
  {
    path: ':s1/:s2/:s3', 
    redirectTo: 'pages/reWriteUrl/:s1/:s2/:s3'   
  },
  {
    path: ':s1/:s2/:s3/:s4', 
    redirectTo: 'pages/reWriteUrl/:s1/:s2/:s3/:s4'   
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    anchorScrolling: 'enabled',
    useHash: false,
    scrollPositionRestoration: 'enabled'
    // scrollPositionRestoration: 'disabled' // set 'disabled' if you don't want the scroll to be happen when the page is loaded
  })],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy}
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }