import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class StorelocatorService {

  constructor(
    public http: HttpClient
  ) { }

   defaultOptions() {
    const options = {
      urlLoadStore: '',
      defaultStoreIcon: '',
      mediaUrlImage: '',
      latitude: 0,
      longitude: 0,
      zoom_level: 4,
      minZoom: 1,
      maxZoom: 20,
      create: null,
      defaultRaidus: '25',
      disabled: false,
      distanceUnit: 'Mi',
      storageUnit: {
        Km: {label: 'Km', factor: 1000},
        M: {label: 'M', factor: 1},
        Mi: {label: 'Mi', factor: 1609.34}
      }
    };
    return options;
  }
  getStores(paramsData) {
    return this.http.get(`${environment.BASE_URL}storelocator/index/stores/`, {params: paramsData} )
      .pipe(
        map((res: any) => {
          if (res) {
            return res.storesjson;
          }
        })
      );
  }

  getStoreDetails(paramsData) {
    return this.http.get(`${environment.BASE_URL}storelocator/index/Storedetail/`, {params: paramsData} )
      .pipe(
        map((res: any) => {
          if (res) {
            return res.storesjson;
          }
        })
      );
  }
  
/*

  getIpAddress() {
    return this.http.get(`https://api.ipify.org?format=json`)
    .pipe(
      map((res: any) => {
        if (res && res.ip) {
          return  res.ip;
        }
      })
    );
  }

  getLocationFromIP(ip){
    return this.http.get(`http://www.geoplugin.net/json.gp?ip=${ip}`)
    .pipe(
      map((res: any) => {
        if (res) {
          return  res;
        }
      })
    );
    
  }
*/

locationDetails() {

  return this.http.get(`${environment.BASE_URL}storelocator/index/locationdetails`)
    .pipe(
      map((res: any) => {
        if (res) {
          return  res;
        }
      })
    );
}
  storeTodayHours(store) {
    let storeHours = '';
    const today =  store.today.toLowerCase();

    if (today == 'sun') {
      if (store.sunday_status == 1 ) {
        storeHours += `
                       <h6><b>Sunday</b></h6><p> ${this.tConvert(store.sunday_open)} - ${this.tConvert(store.sunday_close)}</p>
                   `;
      }
      else{
        storeHours += `<h6><b>Sunday</b></h6><p>  Close</p>`;
      }
    }

    if (today == 'mon') {
      if (store.monday_status == 1 ) {
        storeHours += `
                       <h6><b>Monday</b></h6><p> ${this.tConvert(store.monday_open)} - ${this.tConvert(store.monday_close)}</p>
                   `;
      }
      else{
        storeHours += `<h6><b>Monday</b></h6><p> Close</p>`;
      }
    }


    if (today == 'tue') {
      if (store.tuesday_status == 1 ) {
        storeHours += `
                       <h6><b>Tuesday</b></h6><p> ${this.tConvert(store.tuesday_open)} - ${this.tConvert(store.tuesday_close)}</p>
                   `;
      }
      else{
        storeHours += `<h6><b>Tuesday</b></h6><p> Close</p>`;
      }
    }

    if (today == 'wed') {
      if (store.wednesday_status == 1 ) {
        storeHours += `
                       <h6><b>Wednesday</b></h6><p> ${this.tConvert(store.wednesday_open)} - ${this.tConvert(store.wednesday_close)}</p>
                   `;
      }
      else{
        storeHours += `<h6><b>Wednesday</b></h6><p> Close</p>`;
      }
    }

    if (today == 'thu') {
      if (store.thursday_status == 1 ) {
        storeHours += `
                       <h6><b>Thursday</b></h6><p> ${this.tConvert(store.thursday_open)} - ${this.tConvert(store.thursday_close)}</p>
                   `;
      }
      else{
        storeHours += `<h6><b>Thursday</b></h6><p> Close</p>`;
      }
    }

    if (today == 'fri') {
      if (store.friday_status == 1 ) {
        storeHours += `
                       <h6><b>Friday</b></h6><p> ${this.tConvert(store.friday_open)} - ${this.tConvert(store.friday_close)}</p>
                   `;
      }
      else{
        storeHours += `<h6><b>Friday</b></h6><p> Close</p>`;
      }
    }

    if (today == 'sat') {
      if (store.saturday_status == 1 ) {
        storeHours += `
                       <h6><b>Saturday</b></h6><p> ${this.tConvert(store.saturday_open)} - ${this.tConvert(store.saturday_close)}</p>
                   `;
      }
      else{
        storeHours += `<h6><b>Saturday</b></h6><p> Close</p>`;
      }
    }


    
    return storeHours;

  }
  storeWeekelyHours(store) {
    let storeHours = '';
    storeHours += `<table  class="table table-striped">
      <tbody>
    `;

    if (store.sunday_status == 1 ) {
      storeHours += `  <tr>
      <td>Sun:</td>
         <td>${this.tConvert(store.sunday_open)} - ${this.tConvert(store.sunday_close)}</td>
       </tr>`;
    } else {
      storeHours += `  <tr>
      <td>Sun:</td>
         <td>Close</td>
       </tr>`;
    }

    // Monday
    if (store.monday_status == 1) {
      storeHours += `  <tr>
      <td>Mon:</td>
         <td>${this.tConvert(store.monday_open)} - ${this.tConvert(store.monday_close)}</td>
       </tr>`;
    } else {
      storeHours += `  <tr>
      <td>Mon:</td>
         <td>Close</td>
       </tr>`;
    }

      // Tuesday
    if (store.tuesday_status == 1) {
        storeHours += `  <tr>
        <td>Tue:</td>
           <td>${this.tConvert(store.tuesday_open)} - ${this.tConvert(store.tuesday_close)}</td>
         </tr>`;
      } else {
        storeHours += `  <tr>
        <td>Tue:</td>
           <td>Close</td>
         </tr>`;
      }

         // Wednesday
    if (store.wednesday_status == 1) {
      storeHours += `  <tr>
      <td>Wed:</td>
         <td>${this.tConvert(store.wednesday_open)} - ${this.tConvert(store.wednesday_close)}</td>
       </tr>`;
    } else {
      storeHours += `  <tr>
      <td>Wed:</td>
         <td>Close</td>
       </tr>`;
    }
    
     // Thursday
    if (store.thursday_status == 1) {
      storeHours += `  <tr>
      <td>Thu:</td>
         <td>${this.tConvert(store.thursday_open)} - ${this.tConvert(store.thursday_close)}</td>
       </tr>`;
    } else {
      storeHours += `  <tr>
      <td>Thu:</td>
         <td>Close</td>
       </tr>`;
    }

      // Friday
    if (store.friday_status == 1) {
        storeHours += `  <tr>
        <td>Fri:</td>
           <td>${this.tConvert(store.friday_open)} - ${this.tConvert(store.friday_close)}</td>
         </tr>`;
      } else {
        storeHours += `  <tr>
        <td>Fri:</td>
           <td>Close</td>
         </tr>`;
      }

       // Saturday
    if (store.saturday_status == 1) {
      storeHours += `  <tr>
      <td>Sat:</td>
         <td>${this.tConvert(store.saturday_open)} - ${this.tConvert(store.saturday_close)}</td>
       </tr>`;
    } else {
      storeHours += `  <tr>
      <td>Sat:</td>
         <td>Close</td>
       </tr>`;
    }


    storeHours += ` </tbody>  </table>`;
    return storeHours;

  }

   tConvert (time) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }

  public requestAndTransfer(data: any) {
    return this.http.get(`${environment.BASE_URL}Home/PrivateShowing/RequesttransferGetMethod/`, { params: data })
    .pipe(
      map((res: any) => {
        if (res) {
          return res;
        }
      })
    );
  }
  public requestShowing(data: any) {
    return this.http.get(`${environment.BASE_URL}Home/PrivateShowing/RequesShowingGetMethod/`, { params: data })
    .pipe(
      map((res: any) => {
          return res;
      })
    );
  }
}
