

<div *ngIf="!loader">
  <div class="img-wrapper pb-simple">
    <div class="lable-block mt-2 ml-2 mr-2">
      <span class="lable3 ml-2" *ngIf="product.new">new</span>
      <span class="lable4 ml-2" *ngIf="product.sale">on sale</span>
      

      <span class="lable3" *ngIf="product?.final_price < product?.price.regularPrice.amount.value">
        {{ product?.final_price  | discountPercentage:  product?.price.regularPrice.amount.value | number:'1.0-0'}}% <br>OFF
      </span> 
   

      <ng-container *ngIf="product.promotional_tag">

        <div class="promo-tags special-pro ">
          <span class="brite" *ngIf="checkPromotionalTag(product.promotional_tag, 'brite');"></span>
          <span class="cyber" *ngIf="checkPromotionalTag(product.promotional_tag, 'cyber');"></span>
          <span class="ribbon black" *ngIf="checkPromotionalTag(product.promotional_tag, 'black');"></span>
          <span class="ribbon gold" *ngIf="checkPromotionalTag(product.promotional_tag, 'gold');"></span>
          <span class="special" *ngIf="checkPromotionalTag(product.promotional_tag, 'special');">Special <br>DAY</span>
        </div>
      </ng-container>
    </div>
    <div class="front">
      <a [routerLink]="['/', product.url_key]">
        <img 
          [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
          [lazyLoad]="ImageSrc ? ImageSrc : product.image.url" 
          class="img-fluid lazy-loading" 
          alt="{{ product.image.label }}" />
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div>
   
      <a href="javascript:void(0);">
        <h6>{{ product?.name | titlecase }}</h6>
      </a>
      <p>{{ product?.description }}</p>
      <h4>
        {{ product?.final_price | currency:currency?.currency:'symbol' }}
       
        <del *ngIf="product?.final_price !=  product?.price.regularPrice.amount.value"><span class="money"> {{ product?.price.regularPrice.amount.value | currency:currency?.currency:'symbol'}}</span></del>
      </h4>
   
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

