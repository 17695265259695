import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

declare var gtag;

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {
  
  public themeLogo: string = 'assets/images/danielslogo.jpeg';

  constructor(public router: Router) {
    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      //gtag('config', 'UA-31227-1', {
//        'page_path': event.urlAfterRedirects
  //    });
    })
   }

  ngOnInit(): void {
 
  }

}
