import { Component, OnInit, Input } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GeneralModalComponent } from '../modal/general-modal/general-modal.component';
@Component({
  selector: 'app-aslowasprice',
  templateUrl: './aslowasprice.component.html',
  styleUrls: ['./aslowasprice.component.scss']
})
export class AslowaspriceComponent implements OnInit {
  @Input() asLowAsPrice: any;
  @Input() page: any;
  constructor(
    public productService: ProductService,
    public modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }
  asLowAs() {
    const calculatePrice = (this.asLowAsPrice / 12);
    if (calculatePrice < 28){
    return 28;
    } else {
    return Math.round(calculatePrice);
    }
  }

  openModel() {
    const modalRef = this.modalService.open(GeneralModalComponent,
      {
        scrollable: false,
        windowClass: 'general-modal',
        size: 'lg',
        // keyboard: false,
        // backdrop: 'static'
      });

    const data = {
      showHeader: false,
      content: `
      <p>* Monthly Amount is an estimate for Daniel’s Credit Account customers with no outstanding balance.</p>
      <p>* Actual Monthly Amounts will vary depending on each individual’s financial situation.</p>
      <p>* A credit card or debit card down-payment may be required at checkout.</p>
      <p>* This estimate does not include any other finance partners such as Synchrony and Sunbit.</p>
      `
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  }
  
}