<ng-template class="theme-modal" #sizeChart let-modal>
	<div class="modal-header">
		<div class="header-image">
			<img src="../../../../../assets/images/danielslogo.jpeg" class="img-fluid">
		</div>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
		
      <span aria-hidden="true">&times;</span>
	</button>
	
  </div>
  <div id="ringsize_guide" class="modal-body model-content-tag">
	<ng-container *ngIf="showFirstPage">
		<div class="size_content size_content_ringsize">
			<h3 class="title">Ring Sizing </h3>
				 <p>When selecting a ring size on DanielsJewelers.com, available size options will display on each product page.  Typically women’s sizes are 5 through 9 and men’s sizes are 9 through 12, including half sizes. Due to manufactured rings being cast to an industry standard of size 7 for women and size 10 for men, we may not have the size you selected in stock, even though the size you choose online will be processed. </p>
				 
				 <h3 class="title">How To Determine Your Ring Size</h3>
				 <p>The most accurate way to measure your finger is to visit your nearest Daniel’s Jewelers store where our trained associates will be more than happy to determine your correct size. If this is not an option, we offer an easy-to-use, printable ring size chart for your convenience. Note: Before printing, make sure the ‘Page Scaling’ is set to ‘NONE’ in your printer settings window. <a (click)="showHideRingSizeGuide();" href="javascript:void(0)">Daniel’s Ring Size Guide</a>
				 </p>
				 
				 <h3 class="title">Tips for Proper Ring Measurement</h3>
				 <ul>
					 <li>Verify that the printed Ring Sizer Guide matches a ruler.</li>
					 <li>After cutting out the ring sizer, pull it tight for an accurate size. You may need someone to help you to hold and read the measurement.</li>
					 <li>Measure your finger later in the day, when it is at its largest. Avoid measuring when your fingers are cold.</li>
					 <li>If you are using an existing ring and using the circle guides, make sure you measure using the inside diameter.</li>					
				 </ul>
				 
				 <h3 class="title">Sizing a Ring for Someone Else</h3>
				 <p>Buying a ring for someone else? If possible, try and borrow a ring they currently wear for the finger they’ll wear it on and use our Printable Ring Size Chart and measure the inside diameter. Or, you can use a carrot as a substitute for the wearer’s finger. Simply slip the borrowed ring over the carrot, don’t force it, and mark the spot where the ring stops, then wrap the Printable Ring Size Chart around the carrot ‘above’ the marked spot for the approximate ring size</p>
				 
					 <h3 class="title">Ring Resizing</h3>
					 <p>Resizing can only be done on certain rings. Some rings and bands cannot be resized due to their unique metal composition. For example, bands with very hard metal like tungsten or titanium cannot be altered and must be cast by the manufacturer. Platinum bands can only be sized a few sizes above or below the original casting size. Eternity bands are normally manufactured only in the standard sizes in order to maintain the ring’s strength and design integrity. Rings with intricate carving or ones with gemstones along the shank cannot be resized without adversely affecting their delicate design.</p>
					 
					 <h3 class="title">What If I Did All The Above Steps, But The Size Is Still Wrong?</h3>
					 
					 <p>If after following all of the above steps and your ring size still isn’t right, don’t worry! Just contact our Customer Service (link).</p>  
				 
				 <h3 class="title">Ordering Non-Standard Sized Rings</h3>
				 
				 <p>Some rings may come in a non-standard size depending on the physical design and manufacturer. However, a specific ring may not be available in the size you need on our website and custom sizing may be necessary. In this case, please contact our Customer Service (link) with your specific questions in regards to size and style, and we can discuss availability and provide you with a quote and delivery options.</p>
				 
			</div><!---- END-->
	</ng-container>
	<ng-container *ngIf="!showFirstPage">
		<div class="size_guide_content size_content">
			<h3 class="title">Ring Sizing Guide </h3>
			 <div class="ring_size_guide">
				   <table width="100%">
<caption >Important steps to assure the most accurate measurement</caption>

<tr>
<td><img src="assets/images/Daniels/Inches.png" ></td>

<td>
   <ol>
	   <li>Before printing this ring sizing guide, make sure that page scaling is set to
   “NONE” in your printer settings area.</li>
	   <li> Measure the line on the left to ensure it is exactly 2 inches.</li>
	   <li>If the measurement isn’t accurate, please review your print settings again.   Be sure you are printing full size.</li>
   </ol>

</td>
</tr>
</table>
<div class="ringsize_method_one">

   <h4 class="title">Method 1 - Paper Sizer </h4>
	   <ol>
		   <li>Carefully cut out the paper sizer below. Be sure to make a small slit in the end for the ruler to slide through.</li>
		   <li>Wrap the sizer around your finger with the numbers facing out and slide the rounded end through the slit.</li>
		   <li>For an accurate measurement, pull the sizer tight over the <span class="underline"> widest</span> part of your finger. This could be a knuckle.</li>
		   <li>Read the number that lines up next to the slit. This is your ring size.</li>
	   </ol>
	   <div class="accurate_measurement">
		   <div class="accurate_measurement_im">
				   <img src="assets/images/Daniels/accurate_measurement.png">
					   <div class="figure">
					   <p>Tips for accurate measurement</p>
					   <p>*	Measure your fingers later in the day when they are at their largest</p>
					   <p>*	Avoid measuring your fingers when it is cold, when they are at their smallest.</p>
					   </div>
			   
		   </div>
	   </div>
	   
	   
</div><!---Method 1-->


<div class="ringsize_method_two">

   <h4 class="title">Method 2 - Measure an Existing Ring  </h4>
	   <ol>
		   <li>Choose a ring that fits the desired finger</li>
		   <li>Place the ring over the circles below. Match the inside edge of the ring with the closest circle. This is the inside diameter of the ring.</li>
		   <li>If the ring falls between two sizes, select the larger of the two.</li>
	   </ol>
		   <div class="measure_existing_ring ">
			   <div class="measure_existing_ring_img">
					   <img style="float:right;" src="assets/images/Daniels/inside_diameter.png">
					   
					   <img style="float:right;" src="assets/images/Daniels/ring_size.png">
					   
			   </div>
		   </div>
</div><!---Method 2-->


			 </div><!---ring_size_guide-->
			 <div class="clearfix"></div>
<div class="primary" style="
float: right;
">
<button type="submit" (click)="showHideRingSizeGuide()" class="btn btn-solid" name="send" id="backToRingSizeGuide" ><span>Back</span></button></div>
	   </div><!-- End-->
	</ng-container>
  </div>
  
</ng-template>