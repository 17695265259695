<div class="icon-nav">
  <ul>
    <li *ngIf="!isCheckoutPage" [class.opensearchform]="mainMenuToggle"  class="search-widgets" id="search-widgets">  
      <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="search-form">
        <div class="input-group">
          <input required minlength="3" #formvalue formControlName="searchInput" (focusin)="inputFocus = true" (focusout)="quickSearchFocusOut()" (keydown)="quickSearchSuggest($event)" type="text" class="form-control" placeholder="Search">
          <div class="input-group-append">
            <button (click)="onSubmit()" type="button">
              <span class="lnr lnr-magnifier"></span>
            </button>
          </div>
        </div>
         
        <ng-container *ngIf="inputFocus && formvalue.value.length > 0">
        <div class="quicksearch" *ngIf="suggest || suggestProducts">
          <div class="search-suggestions" *ngIf="suggest">
            <div class=" title">
              Popular Searches
           </div>
           <ul>
             <li class="searchSuggestionlabel" (click)="searchSuggestion(item)" *ngFor="let item of suggest" [innerHTML]="searchSuggestionLabel(item)"></li>
           </ul>
          </div>
          <div class="search-products" *ngIf="suggestProducts">
            <div class=" title">
              Product Matches
           </div>
           <ul>
             <li  (click)="closeSearch()" class="product-list" *ngFor="let product of suggestProducts" >
              <a [routerLink]="getProductLink(product.Url)">
               <div class="product-image">
                  <img [src]="product.Image" >
               </div>
               <div class="product-details" style="overflow: hidden;">
                  <p class="product-name" [innerHTML]="searchSuggestionLabel(product.Name)"></p>
                  <div class="price-wrapper">
                      <!-- <span class="price">${{product.price}}</span> -->
                      <!-- <span class="old-price">${{product.msrp}}</span> -->
                  </div>
               </div>
              </a>
            </li>
           </ul>
          </div>
        </div> 
        </ng-container>
      </form>  
      <button class="toggleIcon" (click)="showHideSearchBar();">
        <span class="lnr lnr-magnifier"></span>
      </button>
    </li>
   
    <li *ngIf="!isCheckoutPage" class="cart-widgets" id="cart-widgets" [class.opencart]="cartToggle">      
      <label (click)="opencart()" class="lnr">
        <span  class="cart_qty_cls">{{totalItemQty }}</span>
      </label>
    </li>
  </ul>
  <div class="mini-cart-popup" *ngIf="mincartPopData">
    <button (click)="closeMinCartSuccessPopup();" class="close">×</button>
  <p><i class="fa fa-check" aria-hidden="true"></i>Item added to your shopping cart</p>
  <div class="mini-popup-wrapper">
    <div class="dynamic-content-popup">
      <img class="img-fluid" [src]="mincartPopData.image">
      <p class="mb-1">{{mincartPopData.name |safe:'bindhtml'}}</p>
      <p class="cart-popup-price"> ${{mincartPopData.price}}</p>
    </div>
    <a [routerLink]="['/checkout']" (click)="closeMinCartSuccessPopup();" class="mini-popup-bottom-checkout btn btn-solid btn-block mb-1 btn-main">
      Checkout
    </a>
    <a routerLink="/" (click)="closeMinCartSuccessPopup();" class="mini-popup-bottom-continue-shopping btn mb-1 btn-block btn-outline">
      Continue Shopping
    </a>	
  </div>
</div>
</div>


<!-- My account-->
<div id='topaccount' class="topaccount">
  <a [routerLink]="" class="top-sidebar-overlay" (click)="closeAccount()"></a>
  <div class="topheader-sidebar-content">
  <span class="lnr closebtn lnr-cross"  title="Close Overlay" (click)="closeAccount()"></span>
    
    <div class="account-sidebar-links">
        <ul class="links-list">
            <li *ngIf="!userLoggedIn" (click)="closeAccount()"><a [routerLink]="'/pages/login'">Log In</a></li>
            <li *ngIf="userLoggedIn" [routerLink]="'/pages/dashboard'" (click)="closeAccount()"><a>My Account</a></li> 
            <li (click)="closeAccount()"><a [routerLink]="'/wishlist'">{{ 'wishlist' | translate }}</a></li>
            <li (click)="closeAccount()"><a [routerLink]="'/compare'">{{ 'compare' | translate }} ({{compareItemsCount}})</a></li>
            <li *ngIf="!userLoggedIn" (click)="closeAccount()"><a [routerLink]="'/pages/register'">Create an Account</a></li>       
            <li (click)="closeAccount()"><a [routerLink]="'/pages/contact'">Contact Us</a></li>
            <li *ngIf="userLoggedIn" (click)="logOut()" (click)="closeAccount()"><a>Log Out</a></li>                       
        </ul>
    </div>
  </div>
</div>

<!-- My account-->
<div id='topcart' class="topcart" [class.open]="cartToggle">
  <a [routerLink]="" class="top-sidebar-overlay" (click)="closeCart()"></a>
  <div class="topheader-sidebar-content mini-cart">
    <div class="block-top text-center">
       <div class="block block-title">Cart</div>
       
    </div>
    <span class="lnr closebtn lnr-cross"  title="Close Overlay" (click)="closeCart()"></span>
   
    <ul class="show-div shopping-cart" *ngIf='!cart || !cart.items || !cart.items.length'>
      <h5>Your cart is currently empty.</h5>
    </ul>
    <ul class="show-div shopping-cart" *ngIf='cart && cart.items && cart.items.length'>
      <li  *ngIf="totalItemQty > 0">
          <p class="mb-0 mt-20"><b>{{totalItemQty}} ITEM<span *ngIf="totalItemQty > 1">S</span></b> IN CART</p>
      </li>
      <ng-container *ngFor="let cartItem of cart.items">
      <li  *ngIf="cartItem?.product">
        <div class="media">
          
          <a [routerLink]="['/', cartItem.product.url_key]" class="mr-3">
            <ng-container *ngIf="cartItem.childproduct != null; else simpleproductimage">
            <img style="width:100px;" [src]="CATALOG_MEDIA_URL+cartItem.childproduct.custom_small_image" [alt]="'assets/images/product/placeholder.jpg'">
          </ng-container>
          <ng-template #simpleproductimage>
            <img style="width:100px;" [src]="CATALOG_MEDIA_URL+cartItem.product.custom_small_image" [alt]="'assets/images/product/placeholder.jpg'">
          </ng-template>
          </a>
          <div class="media-body">
            <a [routerLink]="['/', cartItem.product.url_key]">
              <ng-container *ngIf="cartItem.childproduct != null; else simpleproductitem">
              <h4>{{ cartItem.childproduct.name  |safe:'bindhtml'}}</h4>
            </ng-container>
            <ng-template #simpleproductitem>
              <h4>{{ cartItem.product.name  |safe:'bindhtml' }}</h4>
            </ng-template>
            </a>

            <div class="details-qty qty">
              <input class="form-control" type="text" readonly [value]="cartItem?.quantity" >
            </div>
            
            <h6>
              <span>
                {{ cartItem.prices.row_total.value * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
              </span>
            </h6>
          </div>
        </div>
        <div class="close-circle" *ngIf="cartItem.prices.row_total.value != 0">
          <!-- <a ><i class="fa fa-times" aria-hidden="true"></i></a> -->
          <a  [routerLink]="['/', cartItem.product.url_key]" class="icon edit">
            <i class="lnr lnr-pencil"></i>
          </a>  
          <a (click)="removeItem(cartItem)" class="icon">
            <i class="fa fa-trash-o"></i>
          </a>      
        </div>
      </li>   
    </ng-container>
    </ul>
    <div class="quickcart-bottom" *ngIf='cart && cart.items && cart.items.length'>
      <ul>
        <li>
          <div class="total">
            <h4>Cart subtotal : <span>{{ cart.prices.subtotal_excluding_tax.value | currency:productService?.Currency.currency:'symbol' }}</span></h4>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a [routerLink]="['/checkout']" (click)="closeCart()" class="checkout btn btn-solid btn-main d-flex align-items-center justify-content-center">Go to checkout<i class="fa fa-long-arrow-right"></i></a>
            <a [routerLink]="['/cart']" (click)="closeCart()" class="btn btn-outline">view and edit cart</a>
          </div>
        </li>
      </ul>
    </div>
</div>
  
</div>