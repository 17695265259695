import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, Subject, BehaviorSubject } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product, RequestShowing } from '../classes/product';
import { cartRestApi } from '../classes/cartRestApi';
import { environment } from '../../../environments/environment';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CartResponse, Data, Item, ShippingAddress, AvailableShippingMethod } from '../classes/cartGraphQl';
import { ApolloQueryResult, ApolloCurrentQueryResult } from 'apollo-client';
import { HttpLink } from 'apollo-angular-link-http';
import { GiftMessage } from '../classes/giftMessage';
import { PaymentDetails, SavePaymentDetails } from '../classes/order';
import { InitializeSubitRequest } from '../classes/sunbit'
import 'rxjs/add/observable/of';
import { InMemoryCache } from "apollo-cache-inmemory";
import { Customer } from '../classes/customer';
import { WishList } from '../classes/wishlist';
import { ProductListing } from '../classes/productListing';
import { CompareProduct } from '../classes/compareProduct';
import { AddressToCart } from '../classes/cartRestApi';

const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  public Currency = { name: 'Dollar', currency: 'USD', price: 1 } // Default Currency
  public OpenCart: boolean = false;
  public Products;
  public Cart;
  public miniCart = new Subject<any>();
  public wishList = new Subject<any>();
  public customer: Customer;
  public categoryImage;
  public categoryDescription;
  public wellsFargoPopupInfo;

  private showOrderSuccessPage: BehaviorSubject<boolean>;
  public successPage: Observable<boolean>;
  public tryOnData:BehaviorSubject<any>;
  public tryOnUrl:BehaviorSubject<any>;
  constructor(private http: HttpClient,
    private toastrService: ToastrService, private apollo: Apollo, private httpLink: HttpLink) {
    this.showOrderSuccessPage = new BehaviorSubject<boolean>(false);
    this.successPage = this.showOrderSuccessPage.asObservable();
    this.tryOnData = new BehaviorSubject<boolean>(false);
    this.tryOnUrl = new BehaviorSubject<boolean>(false);
  }

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  // Product
  private get products(): Observable<Product[]> {
    this.Products = this.http.get<Product[]>('assets/data/products.json').pipe(map(data => data));
    this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
    return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  }

  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }

  // Get Products By Slug
  public getProductBySku(sku: string): Observable<Product> {
    const httpOptions = {
      headers: new HttpHeaders()
    }
    return this.http.get<Product>(environment.product_base_url + environment.get_product_url + sku);
  }

  public getProductByTitle(title: string): Observable<any> {
    return this.http.get<any>(environment.api_host + `graphql?query={      products(filter: { url_key: { eq:"` + title + ` " } }) {      items {  attribute_set_id  name  id  sku  retail_price  onsale  holidayship  price{  regularPrice{  amount{  value  currency  }  }  }  special_price  description{html}  url_key  meta_title  meta_description  attribute_Details{  attribute_code  value  label  is_visible_on_front  default_frontend_label  }  media_gallery_entries{  content{  name  type  }  disabled  file  label  media_type  position  types  }  __typename  ... on CustomizableProductInterface {  options {  title  required  sort_order  option_id  type  ... on CustomizableCheckboxOption{  value{  option_type_id  uid  price  title  }  }  ... on CustomizableDropDownOption {  dropdown: value{  uid  option_type_id  price  title  sort_order  }  }  ... on CustomizableFieldOption {  field: value{  uid  price  max_characters  }  }  }  }  ... on ConfigurableProduct {  configurable_options {  id  attribute_id  label  position  use_default  attribute_code  values {  value_index  label  }  product_id  }  variants {  product {  sku url_key}  attributes {  label  code  value_index  uid  }  }  }  }      }      }`);
  }

  public getRelatedByTitle(title: string): Observable<any> {
    return this.http.get<any>(environment.api_host + `graphql?query={products(filter: { url_key: { eq:"` + title + `" } }) {      items {  attribute_set_id  name  id  sku __typename related_products {id name sku onsale retail_price price{  regularPrice{  amount{  value  currency  }  }  }  special_price url_key  media_gallery_entries{  content{  name  type  }  disabled  file  label  media_type  position  types }}}}}`);
  }


  //   public getProductByTitle(title: string): Observable<any> {
  //     return this.http.get<any>(environment.api_host + `graphql?query={
  //       products(filter: { url_key: { eq: "` + title + ` " } }) {

  // items {
  //   attribute_set_id
  //   name
  //   id
  //   sku
  //   retail_price
  //   onsale
  //   holidayship
  //   price{
  //   regularPrice{
  //   amount{
  //   value
  //   currency
  //   }
  //   }
  //   }
  //   special_price
  //   description{html}
  //   url_key
  //   meta_title
  //   meta_description
  //   attribute_Details{
  //   attribute_code
  //   value
  //   label
  //   is_visible_on_front
  //   default_frontend_label
  //   }
  //   media_gallery_entries{
  //   content{
  //   name
  //   type
  //   }
  //   disabled
  //   file
  //   label
  //   media_type
  //   position
  //   types
  //   }
  //   __typename
  //   ... on CustomizableProductInterface {
  //   options {
  //   title
  //   required
  //   sort_order
  //   option_id
  //   type
  //   ... on CustomizableCheckboxOption{
  //   value{
  //   option_type_id
  //   uid
  //   price
  //   title
  //   }
  //   }
  //   ... on CustomizableDropDownOption {
  //   dropdown: value{
  //   uid
  //   option_type_id
  //   price
  //   title
  //   sort_order
  //   }
  //   }
  //   ... on CustomizableFieldOption {
  //   field: value{
  //   uid
  //   price
  //   max_characters
  //   }
  //   }
  //   }
  //   }
  //   ... on ConfigurableProduct {
  //   configurable_options {
  //   id
  //   attribute_id
  //   label
  //   position
  //   use_default
  //   attribute_code
  //   values {
  //   value_index
  //   label
  //   }
  //   product_id
  //   }
  //   variants {
  //   product {
  //   name
  //   sku
  //   id
  //   onsale
  //   holidayship
  //   retail_price
  //   price{
  //   regularPrice{
  //   amount{
  //   value
  //   currency
  //   }
  //   }
  //   }
  //   special_price
  //   description{html}
  //   url_key
  //   stock_status
  //   attribute_Details{
  //   attribute_code
  //   value
  //   label
  //   is_visible_on_front
  //   default_frontend_label
  //   }
  //   media_gallery_entries{
  //   content{
  //   name
  //   type
  //   }
  //   disabled
  //   file
  //   id
  //   label
  //   media_type
  //   position
  //   types
  //   }
  //   __typename
  //   ... on CustomizableProductInterface {
  //   options {
  //   title
  //   required
  //   sort_order
  //   option_id
  //   type
  //   ... on CustomizableCheckboxOption{
  //   value{
  //   option_type_id
  //   uid
  //   price
  //   title
  //   }
  //   }
  //   ... on CustomizableDropDownOption {
  //   dropdown: value{
  //   option_type_id
  //   price
  //   title
  //   sort_order
  //   uid
  //   }
  //   }
  //   ... on CustomizableFieldOption {
  //   field: value{
  //   uid
  //   price
  //   max_characters
  //   }
  //   }
  //   }
  //   }
  //   }
  //   attributes {
  //   label
  //   code
  //   value_index
  //   uid
  //   }
  //   }
  //   }
  //   }
  //       }
  //       }
  //       `);
  //   }
  /*return this.http.get<any>(environment.api_host + `graphql?query={
        products(filter:{ url_key:{eq:"` + title + ` "}}pageSize:20currentPage:1)
        {items{id attribute_set_id name sku type_id product_links { sku link_type linked_product_sku linked_product_type position } price{ regularPrice{ amount{ value currency } } }  attribute_Details{ attribute_code value label is_visible_on_front default_frontend_label } stock_status  country_of_manufacture description{html} short_description{html} meta_title meta_description url_key media_gallery_entries{ content{ name type } disabled file id label media_type position types video_content{ media_type video_description video_metadata video_provider video_title video_url } }  ... on CustomizableProductInterface { options { title required sort_order option_id type ... on CustomizableDropDownOption{ value: value{ option_type_id price title sort_order } } ... on CustomizableRadioOption{ radioOptions: value{ price title option_type_id} } ... on CustomizableCheckboxOption{ value: value{ option_type_id price title sort_order } }... on CustomizableFieldOption{ priceDetails: value{ price max_characters } } } } ... on ConfigurableProduct { configurable_options { id attribute_id label position use_default attribute_code values { value_index label } product_id } variants { product { id name sku attribute_set_id ... on PhysicalProductInterface { weight } media_gallery_entries{ content{ name type } disabled file id label media_type position types video_content{ media_type video_description video_metadata video_provider video_title video_url } } image360_gallery_entries{ position file } price{ regularPrice{ amount{ value currency }}} final_price ... on CustomizableProductInterface {options { title required sort_order option_id type ... on CustomizableDropDownOption{value: value{ option_type_id price title sort_order }} ... on CustomizableCheckboxOption{ value: value{ option_type_id price title sort_order } } ... on CustomizableFieldOption{priceDetails: value{ price max_characters }}}}} attributes { label code value_index }}}}}}
      `); */

  public getProductList(category: string, params: HttpParams): Observable<ProductListing> {
    let queryString = params.toString();
    console.log('queryString', queryString);
    queryString = queryString.replace('removableKey=', '');
    return this.http.get<ProductListing>(`${environment.CATEGORY_API_URL}&${queryString}`);
  }

  public reCreateApolloAfterLoginLogOut() {
    this.apollo.removeClient();

    let headers = new HttpHeaders();
    const token = localStorage.getItem('customerToken');
    if (token) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }

    this.apollo.create({
      link: this.httpLink.create({ uri: environment.api_host + environment.graphql_url, headers: headers }),
      cache: new InMemoryCache()
    });
  }

  public getQuoteId() {
    return localStorage["quoteId"];
  }

  public async getOrCreateQuoteId() {
    let quoteId: string = localStorage["quoteId"];

    if (quoteId) {
      let response = {
        data: {
          createEmptyCart: quoteId
        }
      }
      return await Observable.of(response).toPromise();
    }

    if (!quoteId) {
      this.reCreateApolloAfterLoginLogOut();
    }
    return await this.apollo
      .mutate<any>({
        mutation: gql`mutation {
          createEmptyCart
        }`
      }).toPromise();

    // .subscribe(
    //   (response) => {
    //     quoteId = response.data.createEmptyCart;
    //     if (quoteId) {
    //       localStorage.setItem("quoteId", quoteId);
    //     }
    //     console.log('3');
    //     console.log(response);

    //     return Observable.of(quoteId);
    //   }
    // );
  }

  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<WishList[]> {
    let headers = new HttpHeaders();
    const token = localStorage.getItem('customerToken');
    headers = headers.set('Authorization', 'Bearer ' + token);

    return this.http.get<WishList[]>(environment.product_base_url + environment.get_from_wish_list_url, { headers: headers });
  }

  // Add to Wishlist
  public addToWishlist(productId): Observable<any> {
    let headers = new HttpHeaders();
    const token = localStorage.getItem('customerToken');
    headers = headers.set('Authorization', 'Bearer ' + token);

    return this.http.get(environment.product_base_url + environment.add_to_wish_list_url + productId, { headers: headers });
  }

  // Remove Wishlist items
  public removeWishlistItem(wishListItemId: string): any {
    let headers = new HttpHeaders();
    const token = localStorage.getItem('customerToken');
    headers = headers.set('Authorization', 'Bearer ' + token);

    return this.http.get(environment.product_base_url + environment.remove_from_wish_list_url + wishListItemId, { headers: headers });
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public compareItems(compareItems): Observable<CompareProduct[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken);
    const request: any = {};
    request.productSkus = compareItems;
    return this.http.post<CompareProduct[]>(environment.product_base_url + environment.compare_product_url, request, { headers: headers });
  }

  // Add to Compare
  public addToCompare(product, name = null): any {
    let compareItems = JSON.parse(localStorage['compareItems'] || '[]')
    const compareItem = compareItems.find(item => item === product)
    if (!compareItem) {
      compareItems.push(
        product
      )
    }

    if (name) {
      this.toastrService.success(`You added product ${name}  to the comparison list.`);
    } else {
      this.toastrService.success('Product has been added in compare.');
    }

    localStorage.setItem("compareItems", JSON.stringify(compareItems));
    return true
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    let compareItems = JSON.parse(localStorage['compareItems'] || '[]')
    const index = compareItems.indexOf(product);
    compareItems.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(compareItems));
    return true
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  setMiniCart(cart: any) {
    this.miniCart.next({ cart: cart });
  }

  getMiniCart(): Observable<any> {
    return this.miniCart.asObservable();
  }

  setWishList(wishList: any) {
    this.wishList.next({ wishList: wishList });
  }

  getWishList(): Observable<any> {
    return this.wishList.asObservable();
  }

  // Get Cart Items
  public get cartItems(): Observable<any> {
    var cart_id = localStorage["quoteId"];
    if (!cart_id) {
      cart_id = this.getQuoteId();
    }
    if (cart_id) {
      cart_id = cart_id.split('|')[0];
    }

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    const token = localStorage.getItem('customerToken');
    if (token) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }
    if (cart_id) {
      
   
    return this.http.get<any>(environment.api_host + `graphql?query={
      cart(cart_id: "` + cart_id + `")  {
        items {
        id
        product {
        name
        sku
        url_key
        attribute_set_id
        custom_small_image
        ... on CustomizableProductInterface {
        options {
        title
        required
        sort_order
        option_id
        type
        ... on CustomizableCheckboxOption{
        value{
        option_type_id
        uid
        price
        title
        }
        }
        ... on CustomizableDropDownOption {
        dropdown: value{
        uid
        option_type_id
        price
        title
        sort_order
        }
        }
        ... on CustomizableFieldOption {
        field: value{
        uid
        price
        max_characters
        }
        }
        }
        }
        }
        childproduct {
        name
        sku
        custom_small_image
        ... on CustomizableProductInterface {
        options {
        title
        required
        sort_order
        option_id
        type
        ... on CustomizableCheckboxOption{
        value{
        option_type_id
        uid
        price
        title
        }
        }
        ... on CustomizableDropDownOption {
        dropdown: value{
        uid
        option_type_id
        price
        title
        sort_order
        }
        }
        ... on CustomizableFieldOption {
        field: value{
        uid
        price
        max_characters
        }
        }
        }
        }
        }
        isConfigurable
        ... on SimpleCartItem {
        customizable_options:customizable_options {
        label
        id
        values {
        id
        label
        value
        price{
        value
        }
        }
        }
        }
        ... on ConfigurableCartItem {
        config_customizable_options:customizable_options {
        label
        id
        values {
        id
        label
        value
        price{
        value
        }
        }
        }
        configurable_options {
        id
        option_label
        value_id
        value_label
        }
        }
        quantity
        prices{
        item_price{
        value
        }
        row_total{
        value
        }
        }
        }
        email
        billing_address {
        city
        country {
        code
        label
        }
        firstname
        lastname
        postcode
        region {
        code
        label
        region_id
        }
        street
        telephone
        }
        shipping_addresses {
        firstname
        lastname
        street
        city
        region {
        code
        label
        region_id
        }
        country {
        code
        label
        }
        postcode
        telephone
        available_shipping_methods {
        amount {
        currency
        value
        }
        available
        carrier_code
        carrier_title
        error_message
        method_code
        method_title
        price_excl_tax {
        value
        currency
        }
        price_incl_tax {
        value
        currency
        }
        }
        selected_shipping_method {
        amount {
        value
        currency
        }
        carrier_code
        carrier_title
        method_code
        method_title
        }
        }
        selected_payment_method{
        code
        title
        }
        applied_coupon {
        code
        }
        available_payment_methods {
        code
        title
        }
        prices {
        grand_total {
        value
        }
        subtotal_excluding_tax{
        value
        }
        applied_taxes{
        amount{
        value
        }
        label
        }
        discounts {
        amount{
        value
        }
        label
        }
        }
        }
        }
    `, { headers });
  }
  }

  public set cartItems(value: Observable<any>) {
    var cart_id = localStorage["quoteId"];
    if (!cart_id) {
      cart_id = this.getQuoteId();
    }
    if (cart_id) {
      cart_id = cart_id.split('|')[0];
    }
    if (cart_id) {

      this.cartItems = this.http.get<any>(environment.api_host + `graphql?query={
        cart(cart_id: "`+ cart_id + `") {
          items {
            id
            product {
              name
              sku
              url_key
          small_image{
          url
          }
          ... on CustomizableProductInterface {
            options {
              title
              required
              sort_order
              option_id
              type
              ... on CustomizableDropDownOption{
                value{
                  option_type_id
                  price
                  title
                  sort_order          
                }
              }
              ... on CustomizableCheckboxOption{
                value{
                  option_type_id
                  price
                  title
                  sort_order
                }
              }
            }
          }
            }
          ... on SimpleCartItem {
                customizable_options {
                  label
            id
                  values {
                    id
              label
              value
              price{
              value
              }
                  }
                }
              }
          ... on ConfigurableCartItem {
                configurable_options {
              id
                  option_label
            value_id
            value_label
                }
              }
            quantity
            price
          }
        email
        billing_address {
            city
            country {
              code
              label
            }
            firstname
            lastname
            postcode
            region {
              code
              label
              id
            }
            street
            telephone
          }
        shipping_addresses {
          firstname
            lastname
            postcode
            street
            city
            region {
              code
              label
              id
            }
            country {
              code
              label
            }
            telephone
          available_shipping_methods {
              amount {
                currency
                value
              }
              available
              carrier_code
              carrier_title
              error_message
              method_code
              method_title
              price_excl_tax {
                value
                currency
              }
              price_incl_tax {
                value
                currency
              }
            }
            selected_shipping_method {
              amount {
                value
                currency
              }
              carrier_code
              carrier_title
              method_code
              method_title
            }
          }
        selected_payment_method{
         code
         title
        }
        applied_coupon {
            code
          }
        available_payment_methods {
            code
            title
          }
        prices {
            grand_total {
              value
            }
          subtotal_excluding_tax{
          value
          }
          applied_taxes{
            amount{
            value
            }
            label
            }
            discounts {
            amount{
            value
            }
            label
            }
            }
            }
            }
    `);

    }
  }

  // Add to Cart
  public addToCart(quantity: number, selected_options: string[], entered_options: string[], child_sku: string): any {

    /*cart.cartItem.quote_id = localStorage["quoteId"].split('|')[0];

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    const token = localStorage.getItem('customerToken');
    let url = environment.add_to_cart_guest_url.replace('{0}', cart.cartItem.quote_id)
    if (token) {
      cart.cartItem.quote_id = localStorage["quoteId"].split('|')[1];

      headers = headers.set('Authorization', 'Bearer ' + token);
      url = environment.add_to_cart_customer_url;
    }
    console.log(cart);

    return this.http.post(environment.product_base_url + url, cart, { headers: headers });*/
    let cart_id = localStorage['quoteId'];
    //const cart_item_id = 
    if (!cart_id) {
      cart_id = this.getQuoteId();
    }
    if (cart_id) {
      cart_id = cart_id.split('|')[0];
    }
    return this.apollo
      .mutate<any>({
        mutation: gql`mutation($cart_id: String!, $quantity: Float!, $selected_options: [ID]!, $entered_options: [EnteredOptionInput]!, $sku: String!) {
        addProductsToCart(
          cartId: $cart_id
          cartItems: [
            {
              quantity: $quantity
              sku: $sku
              selected_options: $selected_options
              entered_options: $entered_options
            }
          ]
        ) {
          cart {
            items {
              id
              product {
                name
                sku
              }
              quantity
            }
          }
        }
       }
      `,
        variables: {
          cart_id: cart_id,
          //cart_item_id: cartItem.id,
          quantity: quantity,
          sku: child_sku,
          selected_options: selected_options,
          entered_options: entered_options
        }
      });
  }

  public forgotPassword(email: string): Observable<any> {
    let request: any = {};
    request.email = email;
    request.template = "email_reset";
    request.websiteId = 1;

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put(environment.product_base_url + environment.forgot_password_url, request, { headers: headers });
  }

  public resetPassword(request: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(environment.product_base_url + environment.reset_password_url, request, { headers: headers });
  }

  public contactUs(request: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(environment.product_base_url + environment.contact_us_url, request, { headers: headers });
  }

  public getStores(): Observable<any> {
    return this.http.get<any>(environment.api_host + environment.get_store_url);
  }

  public requestShowing(requestShowing: RequestShowing): Observable<any> {
    console.log(requestShowing);
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(environment.api_host + environment.request_showing_url, requestShowing, { headers: headers });
  }
  // Update Cart Quantity
  public updateCartQuantity(product: Product, quantity: number): Product | boolean {
    return state.cart.find((items, index) => {
      if (items.id === product.id) {
        const qty = state.cart[index].quantity + quantity
        const stock = this.calculateStockCounts(state.cart[index], quantity)
        if (qty !== 0 && stock) {
          state.cart[index].quantity = qty
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true
      }
    })
  }

  public updateCartItem(cartItem: Item, quantity: number, selected_options: string[], entered_options: string[], child_sku: string) {
    let addItemToCart = this.addToCart(quantity, selected_options, entered_options, child_sku);
    cartItem.quantity = 0;
    let removeCartItem = this.updateCartItemQuantity(cartItem);
    return forkJoin([removeCartItem, addItemToCart]);
  }

  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity
    const stock = product.stock
    if (stock < qty || stock == 0) {
      this.toastrService.error('You can not add more items than available. In stock ' + stock + ' items.');
      return false
    }
    return true
  }

  // Remove Cart items
  public updateCartItemQuantity(cartItem: Item): any {
    var cart_id = localStorage["quoteId"];
    if (!cart_id) {
      cart_id = this.getQuoteId();
    }
    if (cart_id) {
      cart_id = cart_id.split('|')[0];
    }

    return this.apollo
      .mutate<any>({
        mutation: gql`mutation($cart_id: String!, $cart_item_id: Int!, $quantity: Float!) {
          updateCartItems(
            input: {
              cart_id: $cart_id,
              cart_items: [
                {
                  cart_item_id: $cart_item_id
                  quantity: $quantity
                }
              ]
              }
            ){
              cart {
                items {
                  id
                  product {
                    name
                  }
                  quantity
                }
                prices {
                  grand_total{
                    value
                    currency
                  }
                }
              }
            }
          }          
        `,
        variables: {
          cart_id: cart_id,
          cart_item_id: cartItem.id,
          quantity: cartItem.quantity
        }
      });
  }

  // Total amount 
  cartTotalAmount(): Observable<number> {
    // return this.cartItems.pipe(map((product: Product[]) => {
    //   return product.reduce((prev, curr: Product) => {
    //     let price = curr.price;
    //     if(curr.discount) {
    //       price = curr.price - (curr.price * curr.discount / 100)
    //     }
    //     return (prev + price * curr.quantity) * this.Currency.price;
    //   }, 0);
    // }));
    return null;
  }

  checkIfEmailAddressRegistered(email: string): Observable<ApolloQueryResult<any>> {
    return this.apollo
      .query<any>({
        query: gql`
        query($email: String!) {
          isEmailAvailable(email: $email) {
          is_email_available
          }
          }`,
        variables: {
          email: email
        }
      });
  }

  public setGuestEmailOnCart(email: string): Observable<any> {
    var cart_id = localStorage["quoteId"];
    if (!cart_id) {
      cart_id = this.getQuoteId();
    }

    if (cart_id) {
      cart_id = cart_id.split('|')[0];
    }

    return this.apollo
      .mutate<any>({
        mutation: gql`mutation($email: String!, $cart_id: String!) {
          setGuestEmailOnCart(input: {
            cart_id: $cart_id
            email: $email
          }) {
            cart {
              email
            }
          }
        }`,
        variables: {
          email: email,
          cart_id: cart_id
        }
      });
  }

  public setAddressesOnCart(addressToCart: AddressToCart) {
    let quote_id = localStorage["quoteId"].split('|')[0];

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    const token = localStorage.getItem('customerToken');
    let url = environment.address_to_cart_guest_url.replace('{0}', quote_id)
    if (token) {
      quote_id = localStorage["quoteId"].split('|')[1];
      headers = headers.set('Authorization', 'Bearer ' + token);
      url = environment.address_to_cart_customer_url;
    }

    return this.http.post(environment.product_base_url + url, addressToCart, { headers: headers });
  }

  public getShippingMethods(address: any): Observable<AvailableShippingMethod[]> {
    let quote_id = localStorage["quoteId"].split('|')[0];

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    const token = localStorage.getItem('customerToken');
    let url = environment.get_shiping_methods_guest_url.replace('{0}', quote_id)
    if (token) {
      quote_id = localStorage["quoteId"].split('|')[1];
      headers = headers.set('Authorization', 'Bearer ' + token);
      url = environment.get_shiping_methods_customer_url;
    }

    return this.http.post<AvailableShippingMethod[]>(environment.product_base_url + url, address, { headers: headers });
  }

  public setShippingAddressesOnCart(shippingAddress: ShippingAddress): Observable<any> {
    let cart_id = localStorage['quoteId'];
    if (!cart_id) {
      cart_id = this.getQuoteId();
    }
    if (cart_id) {
      cart_id = cart_id.split('|')[0];
    }
    //  console.log('shippingAddress---------------');
    // console.log(shippingAddress);

    return this.apollo
      .mutate<any>({
        mutation: gql`mutation($cart_id: String!, $firstname: String!, $lastname: String!,  $street: [String]!,  $city: String!,
          $region: String!, $postcode: String!, $country_code: String!, $telephone: String!, $save_in_address_book: Boolean!) {
          setShippingAddressesOnCart(
            input: {
              cart_id: $cart_id
              shipping_addresses: {
                address: {
                  firstname: $firstname
                  lastname: $lastname
                  street: $street
                  city: $city
                  region: $region
                  postcode: $postcode
                  country_code: $country_code
                  telephone: $telephone
                  save_in_address_book: $save_in_address_book
                }
              }
            }
          ) {
            cart {
              shipping_addresses  {
                firstname
                lastname
                street
                city
                region{
                  code
                  label
                }
                postcode
                telephone
                country {
                  code
                  label
                }
              }
            }
          }
        }
        `,
        variables: {
          cart_id,
          firstname: shippingAddress.firstname,
          lastname: shippingAddress.lastname,
          street: shippingAddress.street,
          city: shippingAddress.city,
          region: shippingAddress.region.id,
          postcode: shippingAddress.postcode,
          telephone: shippingAddress.telephone,
          country_code: shippingAddress.country.code,
          save_in_address_book: false,
        }
      });
  }

  public setBillingAddressOnCart(billingAddress: any): Observable<any> {
    let cart_id = localStorage['quoteId'];
    if (!cart_id) {
      cart_id = this.getQuoteId();
    }
    if (cart_id) {
      cart_id = cart_id.split('|')[0];
    }
    //console.log('billingAddress---------------');
    //console.log(billingAddress);
    return this.apollo
      .mutate<any>({
        mutation: gql`mutation($cart_id: String!, $firstname: String!, $lastname: String!, $street: [String]!, $city: String!,
          $region: String!, $postcode: String!, $country_code: String!, $telephone: String!, $save_in_address_book: Boolean!) {
            setBillingAddressOnCart(
              input: {
                cart_id: $cart_id
                billing_address: {
                  address: {
                    firstname: $firstname
                    lastname: $lastname
                    street: $street
                    city: $city
                    region: $region
                    postcode: $postcode
                    country_code: $country_code
                    telephone: $telephone
                    save_in_address_book: $save_in_address_book
                  }
                }
              }
            ) {
              cart {
                billing_address {
                  firstname
                  lastname
                  street
                  city
                  region{
                    code
                    label
                  }
                  postcode
                  telephone
                  country {
                    code
                    label
                  }
                }
              }
            }
          
        }
        `,
        variables: {
          cart_id,
          firstname: billingAddress.firstname,
          lastname: billingAddress.lastname,
          street: billingAddress.street,
          city: billingAddress.city,
          region: billingAddress.region.id,
          postcode: billingAddress.postcode,
          telephone: billingAddress.telephone,
          country_code: billingAddress.country.code,
          save_in_address_book: false,
        }
      });
  }

  public setShippingMethodsOnCart(carrier_code: any, method_code: any): Observable<any> {
    var cart_id = localStorage["quoteId"];
    if (!cart_id) {
      cart_id = this.getQuoteId();
    }
    if (cart_id) {
      cart_id = cart_id.split('|')[0];
    }
    return this.apollo
      .mutate<any>({
        mutation: gql`mutation($cart_id: String!, $carrier_code: String!, $method_code: String!) {
            setShippingMethodsOnCart(input: {
              cart_id: $cart_id
              shipping_methods: [
                {
                  carrier_code: $carrier_code
                  method_code: $method_code
                }
              ]
            }) {
              cart {
                shipping_addresses {
                  selected_shipping_method {
                    carrier_code
                    method_code
                    carrier_title
                    method_title
                  }
                }
              }
            }
          
        }
        `,
        variables: {
          cart_id: cart_id,
          carrier_code: carrier_code,
          method_code: method_code
        }
      });
  }

  public setPaymentMethodOnCart(code: string): Observable<any> {
    var cart_id = localStorage["quoteId"];
    if (!cart_id) {
      cart_id = this.getQuoteId();
    }
    if (cart_id) {
      cart_id = cart_id.split('|')[0];
    }
    return this.apollo
      .mutate<any>({
        mutation: gql`mutation($cart_id: String!, $code: String!) {
          setPaymentMethodOnCart(input: {
              cart_id: $cart_id
              payment_method: {
                code: $code
            }
            }) {
              cart {
                selected_payment_method {
                  code
                  title
                }
              }
            }
          } 
        `,
        variables: {
          cart_id: cart_id,
          code: code
        }
      });
  }

  public setPaymentMethodOnCartPayPal(paypal: any): Observable<any> {
    var cart_id = localStorage["quoteId"];
    if (!cart_id) {
      cart_id = this.getQuoteId();
    }
    if (cart_id) {
      cart_id = cart_id.split('|')[0];
    }
    console.log('paypal', paypal);
    var payer_id = paypal?.payer_id;
    var token = paypal?.token;
    return this.apollo
      .mutate<any>({
        mutation: gql`mutation($cart_id: String!, $payer_id: String!,$token: String!) {
          setPaymentMethodOnCart(input: {
              cart_id: $cart_id
              payment_method: {
                code: "paypal_express",
                paypal_express: {
                  payer_id: $payer_id,
                  token: $token
                }
            }
            }) {
              cart {
                selected_payment_method {
                  code
                  title
                }
              }
            }
          } 
        `,
        variables: {
          cart_id: cart_id,
          payer_id: payer_id,
          token: token
        }
      });
  }

  public applyCouponToCart(couponCode: string): Observable<any> {
    var cart_id = localStorage["quoteId"];
    if (!cart_id) {
      cart_id = this.getQuoteId();
    }
    if (cart_id) {
      cart_id = cart_id.split('|')[0];
    }
    return this.apollo
      .mutate<any>({
        mutation: gql`mutation($cart_id: String!, $coupon_code: String!) {
          applyCouponToCart(
            input: {
              cart_id: $cart_id
              coupon_code: $coupon_code
            }
          ) {
            cart {
              applied_coupon {
                code
              }
            }
          }
        
          } 
        `,
        variables: {
          cart_id: cart_id,
          coupon_code: couponCode
        }
      });
  }

  public removeCouponFromCart(): Observable<any> {
    var cart_id = localStorage["quoteId"];
    if (!cart_id) {
      cart_id = this.getQuoteId();
    }
    if (cart_id) {
      cart_id = cart_id.split('|')[0];
    }
    return this.apollo
      .mutate<any>({
        mutation: gql`mutation($cart_id: String!) {
          removeCouponFromCart(input: { cart_id: $cart_id }) {
            cart {
              applied_coupon {
                code
              }
            }
          }
        } 
        `,
        variables: {
          cart_id: cart_id
        }
      });
  }

  public addGiftMessageToOrder(giftMessage: GiftMessage): any {
    var cart_id = localStorage["quoteId"];
    if (!cart_id) {
      cart_id = this.getQuoteId();
    }
    if (cart_id) {
      cart_id = cart_id.split('|')[1];
    }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');

    return this.http.post(environment.product_base_url + environment.addGiftMessageUrl.replace('{0}', cart_id), giftMessage, { headers: headers });
  }

  public paypal() {
    var cart_id = localStorage["quoteId"];
    if (!cart_id) {
      cart_id = this.getQuoteId();
    }
    if (cart_id) {
      cart_id = cart_id.split('|')[0];
    }
    return this.apollo.mutate({
      mutation: gql`mutation($cart_id: String!) {
      createPaypalExpressToken(
        input: {
          cart_id: $cart_id
          code: "paypal_express"
          urls: {
            return_url: "Payment/Action/Success"
            cancel_url: "Payment/Action/Cancel"
          }
        }
      ) {
        token
        paypal_urls {
          start
          edit
        }
        }
      }`,
      variables: {
        cart_id,
      }
    })
  }

  public placeOrder(transid = null): Observable<any> {
    var cart_id = localStorage["quoteId"];
    if (!cart_id) {
      cart_id = this.getQuoteId();
    }
    if (cart_id) {
      cart_id = cart_id.split('|')[0];
    }
    return this.apollo
      .mutate<any>({
        mutation: gql`mutation($cart_id: String!) {
          placeOrder(input: { cart_id: $cart_id }) {
            order {
              order_id
            }
          }
        }
    `,
        variables: {
          cart_id,
          trans_id: transid
        }
      });
  }

  public placeTempusOrder(tempusOrderDetails: any): Observable<any> {
    tempusOrderDetails.quote_details.quote_id = this.getQuoteId()?.split('|')[0];
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(`${environment.API_BASE_URL}getTempusPay/`, tempusOrderDetails, { headers });
  }

  public chargeNewFinPayment(ccsystemcode: any): Observable<any> {
    let req = {
      auth_details: {
        ccsystemcode: Number.parseInt(ccsystemcode),
        quote_id: this.getQuoteId()?.split('|')[0]
      }
    };
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(`${environment.API_BASE_URL}splitPayment/capture/`, req, { headers });
  }

  public cancelNewFinPayment(ccsystemcode: any): Observable<any> {
    let req = {
      auth_details: {
        ccsystemcode: Number.parseInt(ccsystemcode),
        quote_id: this.getQuoteId().split('|')[0]
      }
    };
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(`${environment.API_BASE_URL}splitPayment/cancelAuthorization/`, req, { headers });
  }

  public authorizeNewFinPayment(newFinCardDetails: any): Observable<any> {
    newFinCardDetails.paymentDetails.quote_id = this.getQuoteId().split('|')[0];
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(`${environment.API_BASE_URL}splitPayment/authorization/`, newFinCardDetails, { headers });
  }

  public validateExternalUrl(url: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(`${environment.API_BASE_URL}daniel/payment/validateurl/`, url, { headers });
  }
  public authorizeExternalFinPayment(): Observable<any> {
    return this.http.get(`https://instoreportal-associates-stage-svc.azurewebsites.net/v1/associates/credit-app/token/1D2B3949-FCB3-4D7D-A99F-0E762F156890`);
  }

  public createSessionExternalFinPayment(token:any,params: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/json;');
    return this.http.post(`https://instoreportal-associates-stage-svc.azurewebsites.net/v1/associates/create-session`, params, { headers });
  }
  public setTransactionDetails(tranDetails: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(`${environment.API_BASE_URL}splitPayment/setTransactionDetails/`, tranDetails, { headers });
  }

  public dpayNewNoApplication(orderDetails: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(`${environment.API_BASE_URL}daniel/payment/dpaynewnoapplication/`, orderDetails, { headers });
  }

  /* public placeAuthorizeNetOrder(authorizeNetOrderDetails: any): Observable<any> {
     authorizeNetOrderDetails.quote_details.quote_id = this.getQuoteId().split('|')[0];
   
     let headers = new HttpHeaders();
     headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');
   
     return this.http.post(environment.product_base_url + environment.authorize_net_order_url, authorizeNetOrderDetails, { headers: headers });
   }
  */
  getWellsfargoPlans() {
    let params = new HttpParams();
    params = params.append('query', `{
      cmsBlocks(identifiers: "wellsfargo_plans") {
      items {
      identifier
      title
      content
      }
      }
      }`);

    return this.http.get(`${environment.api_host}graphql`, { params })
      .pipe(
        map(res => {
          if (res) {
            console.log();
            if (res['data'].cmsBlocks.items[0].content) {
              return this.removeTags(res['data'].cmsBlocks.items[0].content)
            }
          }
        })
      );
  }

  wellsFargoOrder(params: FormData) {
    var cart_id = localStorage["quoteId"];
    if (!cart_id) {
      cart_id = this.getQuoteId();
    }
    if (cart_id) {
      cart_id = cart_id.split('|')[0];
    }

    params.append("quoteId", cart_id);
    return this.http.post(environment.product_base_url + environment.wells_fargo_order_url, params);
  }

  removeTags(str) {
    if ((str === null) || (str === '')) {
      return false;
    }
    else {
      str = str.toString();
    }

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/ig, '');
  }

  public savePaymentDetails(paymentDetails: SavePaymentDetails): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken).set('Content-Type', 'application/json; charset=utf-8');;

    return this.http.post(`${environment.API_BASE_URL}savePaymentDetails/`, paymentDetails, { headers });
  }

  getCountries(): Observable<ApolloQueryResult<any>> {
    return this.apollo
      .query<any>({
        query: gql`
        query {
          countries {
            id
            two_letter_abbreviation
            three_letter_abbreviation
            full_name_locale
            full_name_english
            available_regions {
                id
                code
                name
            }
          }
       }`
      });
  }
  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return this.products.pipe(map(product =>
      product.filter((item: Product) => {
        if (!filter.length) return true
        const Tags = filter.some((prev) => { // Match Tags
          if (item.tags) {
            if (item.tags.includes(prev)) {
              return prev
            }
          }
        })
        return Tags
      })
    ));
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {

    if (payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      })
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  searchQuickSearchSuggest(searchString) {
    const url = 'https://suggest.searchspring.net/api/suggest/query';
    const data =
    {
      siteId: 'm9stph',
      query: searchString,
      lang: 'en'
    };

    return this.http.post(url, data);
  }

  searchAutoComplete_old(searchString) {
    const url = 'https://api-cache.searchspring.io/api/search/autocomplete.json';

    let params = new HttpParams();
    params = params.append('ajaxCatalog', 'v3');
    params = params.append('resultsFormat', 'native');
    params = params.append('siteId', 'm9stph');
    params = params.append('resultsPerPage', '4');
    params = params.append('q', searchString);
    return this.http.get(url, { params });
  }

  searchAutoComplete(searchString) {

    let params = new HttpParams();
    // params = params.append('ajaxCatalog', 'v3');
    // params = params.append('resultsFormat', 'native');
    // params = params.append('siteId', 'm9stph');
    // params = params.append('resultsPerPage', '4');
    params = params.append('q', searchString);
    const responseType = 'text';
    return this.http.get(`${environment.searchAutoCompleteUrl}`, { params, responseType });
  }

  initializeSunbit(initializeSubitRequest: InitializeSubitRequest): any {
    let headers = new HttpHeaders();
    headers = headers.set('sunbit-key', environment.sunbit.sunbit_key)
      .set('sunbit-secret', environment.sunbit.sunbit_secret)
      .set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(`${environment.sunbit.initializeSubitRequestUrl}`, initializeSubitRequest, { headers });

  }

  validateSunbitCall(transactionId: string, purchaseId: string): any {
    let headers = new HttpHeaders();
    headers = headers.set('sunbit-key', environment.sunbit.sunbit_key)
      .set('sunbit-secret', environment.sunbit.sunbit_secret);
    return this.http.get(environment.sunbit.validateSubitRequestUrl.replace('{0}', transactionId).replace('{1}', purchaseId), { headers });
  }

  saveDanielsCreditDetails(request): any {
    let headers = new HttpHeaders();
    headers = headers
      .set('Authorization', 'Bearer ' + environment.adminBearerToken)
      .set('Content-Type', 'application/json; charset=utf-8');

    return this.http.post(`${environment.product_base_url + environment.saveDanielsCreditDetailsUrl}`, request, { headers });
  }

  getTaxAmount(request): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers
      .set('Authorization', 'Bearer ' + environment.taxJar.api_key)
      .set('Content-Type', 'application/json; charset=utf-8');

    return this.http.post(`${environment.taxJar.api_url}`, request, { headers });
  }

  synchronyAuthentication(data) {

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    const token = environment.adminBearerToken;

    const url = `${environment.API_BASE_URL}Synchrony/Authentication`;
    if (token) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }
    return this.http.post(url, data, { headers });
  }

  synchronyStatusCall(data) {

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    const token = environment.adminBearerToken;
    const url = `${environment.API_BASE_URL}Synchrony/Status/`;
    if (token) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }
    return this.http.post(url, data, { headers });
  }

  public get successPageStatus(): boolean {
    return this.showOrderSuccessPage.value;
  }
  enableSuccessPage() {
    this.showOrderSuccessPage.next(true);
  }
  disableSuccessPage() {
    this.showOrderSuccessPage.next(false);
  }
  getDpayToken() {
    const cartId = localStorage.quoteId.split('|')[0];
    console.log('cartId', cartId);

    const data = {
      quote_id: cartId
    };
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    const token = localStorage.getItem('customerToken');
    if (token) {
      // data.quote_id = localStorage.quoteId.split('|')[1];
      headers = headers.set('Authorization', 'Bearer ' + token);
    } else {
      headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken);
    }
    console.log('data', data);

    return this.http.post(`${environment.API_BASE_URL}daniel/payment/getDPayToken/`, data, { headers });


  }

  dpayCheckIsValidTransition(data) {
    //  data = JSON.stringify(data);
    const cartId = localStorage.quoteId.split('|')[0];
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    const token = localStorage.getItem('customerToken');
    if (token) {
      data.quote_id = localStorage.quoteId.split('|')[1];
      headers = headers.set('Authorization', 'Bearer ' + token);
    } else {
      headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken);
    }

    return this.http.post(`${environment.API_BASE_URL}daniel/payment/checkIsValidTransaction/`, data, { headers });

  }

  newDpaycheckIsValidFrameURL(url) {
    const responseType = 'text';
    return this.http.get(url, { responseType });
  }

  sendCompletePacket(data) {
    data = JSON.stringify(data);
    // const cartId = localStorage.quoteId.split('|')[0];
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    const token = localStorage.getItem('customerToken');
    // if (token) {
    //   headers = headers.set('Authorization', 'Bearer ' + token);
    // } else {
    headers = headers.set('Authorization', 'Bearer ' + environment.adminBearerToken);
    //   }

    return this.http.post(`${environment.API_BASE_URL}daniel/payment/sendCompletePacket/`, data, { headers });

  }

  addTocartUploadData(params,quote_id){
    let headers = new HttpHeaders();
    const token = localStorage.getItem('customerToken');
    let url;
    if(quote_id){
    url = `${environment.API_BASE_URL}guest-carts/${quote_id}/items`;
    } else{
      if (token) {
        headers = headers.set('Authorization', 'Bearer ' + token);
      }
      url = `${environment.API_BASE_URL}carts/mine/items`;
    }
    return this.http.post(url, params, { headers });
  }

  getProductListsJson(): Observable<any>{
    // return this.http.get(environment.tryOnAugmentes);
    return this.http.get('assets/data/tryonProduct.json')
  }
}



