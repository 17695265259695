import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-offer',
  templateUrl: './popup-offer.component.html',
  styleUrls: ['./popup-offer.component.scss']
})
export class PopupOfferComponent implements OnInit, OnDestroy  {
  
  @Input() content: any;

  @ViewChild("popupOffer", { static: false }) PopupOffer: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    // this.modalOpen = true;    
    // console.log(this.content); 
    // if (isPlatformBrowser(this.platformId)) { // For SSR 
    //   this.modalService.open(this.PopupOffer, { 
    //     size: 'md',
    //     ariaLabelledBy: 'popup-offer',
    //     centered: true,
    //     windowClass: 'PopupOffer'
    //   }).result.then((result) => {
    //     `Result ${result}`
    //   }, (reason) => {
    //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    //   });
    // }
  }

  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.PopupOffer, { 
        size: 'md',
        ariaLabelledBy: 'popup-offer',
        centered: true,
        windowClass: 'PopupOffer' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

}
