

<owl-carousel-o [options]="CollectionSliderConfig" class="category-m top-arrow">
    <ng-container *ngFor="let cats of categories">
      <ng-template carouselSlide>
        <div>
          <div class="category-wrapper" [ngClass]="class">
            <div>
              <a href="#">
              <img src="{{cats.Image}}" class="img-fluid" alt="">
            
              <div class="caption">
                <h4 class="h4">{{cats.Category_Name}}</h4>
                <p>{{cats.Description}}</p>
            </div>
             </a>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>