import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { Result } from '../../../classes/productListing';
import { environment } from '../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/shared/services/account.service';

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  @Input() product: Result;
  public environment: any = environment;
  
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc : string

  constructor(private router: Router,
    public productService: ProductService, private toastrService: ToastrService,
    public accountService: AccountService ) { }
  ngOnInit(): void {
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    //this.productService.addToCart(product);
  }

  checkPromotionalTag(tag, str) {
    const promTag =  tag.toLowerCase();
    if (promTag.includes(str)) {
      return true;
    }
    return false;
 }

 getProductLink(product) {
 /* const attributeSetId =  product.attribute_set_id[0];
  if (attributeSetId == 12) {
    return ['/design-your-own/', product.sku];
  }else {
    return ['/product/', product.url_key];
  }*/
  const productURL =  product.Url.replace(/^.*\/\/[^\/]+/, '').substring(1);
  return ['/', productURL];
 }

 addToWishlist() {
  if (!localStorage.getItem('customerToken')) {
    if(confirm("You need to login to add this item to your wishlist. Do you want to login?")) {
      this.router.navigate(['/pages/login'], { queryParams: { source: 'shop/product/' + this.product.Urlkey } });        
    }
  }
  else {
    this.productService.addToWishlist(this.product.Productid).subscribe(response => {
      this.productService.wishlistItems.subscribe(response => {
        this.productService.setWishList(response);
      });

      this.toastrService.success(`Product <b>${this.product.Name}</b> has been added to your Saved Items`);
    },
      error => {
        this.toastrService.error('Sorry there is an error while adding this item to wishlist');
      });
  }
}


addToCompare(event) {
  this.productService.addToCompare(this.product.Sku, this.product.Name);
  event.srcElement.classList.add('success');
}

getPrecentageNumber(specialPrice, salePrice) {
  console.log(specialPrice, salePrice);
  console.log(specialPrice < salePrice);
  let pNumber = 0;
  if (specialPrice < salePrice){
    const discountPercentage = (salePrice - specialPrice) / salePrice * 100;
    pNumber = discountPercentage;
  }
  return pNumber;
}

asLowas(price) {
  return (price >= 100 && price <= 4000) ? true : false;
}
onStartRecording(event){
  this.productClick('openlink');
  let image = document.getElementById('image-url') as HTMLElement;
  image.setAttribute('target', '_blank');
}
productClick(value){
  this.productService.getProductByTitle(this.product?.Urlkey).subscribe(response => {
    if(response.data.products.items.length === 0) {
  this.accountService.reWriteUrl(this.product?.Urlkey)
  .subscribe((result: any) => {
    if(result?.type === 'cms-page') {
      if(value !== 'openlink' ){
        this.router.navigate(['/',this.product?.Urlkey]);
        let image = document.getElementById('image-url') as HTMLElement;
        image.setAttribute('target', '');
      }
    }else{
      if(value !== 'openlink' ){
      this.router.navigate(['/404'], { replaceUrl: true });
      }
    }
  });
}else{
  if(value !== 'openlink' ){
    this.router.navigate(['/',this.product?.Urlkey]);
    let image = document.getElementById('image-url') as HTMLElement;
    image.setAttribute('target', '');
  }
}
});
}
}
