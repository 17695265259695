import { Component, Injectable, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CMSService } from './shared/services/cms.service';
import { LoggingService } from './shared/services/logging.service';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public themeLogo: string = 'assets/images/augvueLogo.png'; // Change Logo
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private _renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document,
    private loader: LoadingBarService, translate: TranslateService, public router: Router, private cmsService: CMSService,
    private loggingService: LoggingService) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
    }
    //this.getLoadGMTScript();
    //this.getLoadGMTNoScript();
    const node = document.createElement('script');
    // node.src = 'assets/js/nextopia.js';
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);

    // // this.router.events.subscribe((event: any) => {
    // //   if (event.url == '/') {
    // //     router.navigateByUrl('/home')
    // //   }
    // // }
    // );
    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      //gtag('config', 'UA-31227-1', {
      //  'page_path': event.urlAfterRedirects
      //});
    })
  }

  getLoadGMTScript() {
    this.cmsService.getGTMScript().subscribe((res: any) => {
  //    const head = document.getElementsByTagName('head')[0];
      //const script = document.createElement('script');
      //script.innerHTML = res?.data?.cmsBlocks?.items[0]?.content;
      //head.insertBefore(script, head.lastChild);
    })
  }

  getLoadGMTNoScript() {
    this.cmsService.getGTMNoScript().subscribe((res: any) => {
    //  let chatScript = document.createElement("noscript");
//      chatScript.textContent = res?.data?.cmsBlocks?.items[0]?.content;
      //document.body.appendChild(chatScript);
    })
  }
}
