import {
  Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Product, RequestShowing } from "../../../classes/product";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AccountService } from '../../../services/account.service';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'request-showing',
  templateUrl: './request-showing.html',
  styleUrls: ['./request-showing.scss']
})
export class RequestShowingComponent implements OnInit, OnDestroy {

  @Input() product: Product;
  public requestShowingForm: FormGroup;
  @ViewChild("requestShowing", { static: false }) RequestShowing: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  public stores;
  public formSubmitted: boolean;
  public message: string;
  public isSaved: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal, private fb: FormBuilder, private productService: ProductService) {
    this.requestShowingForm = this.fb.group({
      customer_name: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phone: ['', [Validators.required]],
      store: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.productService.getStores().subscribe(response => {
      this.stores = response.stores;
    });
  }

  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.RequestShowing, {
        size: 'md',
        ariaLabelledBy: 'request-showing',
        centered: true,
        windowClass: 'RequestShowing'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  submit() {
    this.formSubmitted = true;
    if (this.requestShowingForm.valid) {
      let requestShowing: RequestShowing = {
        sku: this.product.sku,
        customer_email: this.requestShowingForm.controls["email"].value,
        customer_name: this.requestShowingForm.controls["customer_name"].value,
        customer_phone: this.requestShowingForm.controls["phone"].value,
        store_email: this.requestShowingForm.controls["store"].value,
        store_name: (this.stores.filter(s => s.value == this.requestShowingForm.controls["store"].value)[0]).name
      };

      this.productService.requestShowing(requestShowing).subscribe(response => {
        this.isSaved = true;
        this.message = response.message;
      }); 
    }
  }
}
