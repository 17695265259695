<!-- header start -->
<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12" id="promotion_top_bar">
          <!--<span [innerHtml]="messageFromDanielsJewelers | safe:'bindhtml'"></span>-->
      </div>
    </div>
  </div>
  <div class="main-menu">
    <div class="header-wrapper">
      <div class="top-menu-header">
        <ul class="contact-items">
          <li class="contact-number"><a [routerLink]="['contact-us']"><span class="lnr lnr-phone-wave"></span><span class="top-menu-text">Contact Us</span></a></li>
          <li class="storelocator" [class.storehide]="!showMegamenu"><a [routerLink]="['storelocator']"><span class="lnr lnr-map-marker"></span><span class="top-menu-text p-0"> Store Locations</span></a></li>
          </ul>

          <ul class="addtional-menu" >
            <li *ngIf="userLoggedIn" (click)="logOut()"><a ><span class="lnr lnr-user"></span><span class="top-menu-text">Sign Out</span></a></li>
            <li *ngIf="userLoggedIn"><a [routerLink]="'/pages/dashboard'"><span class="lnr lnr-user"></span><span class="top-menu-text">My Account</span></a></li>            
            <li *ngIf="!userLoggedIn"><a [routerLink]="['/pages/login']"><span class="lnr lnr-user"></span><span class="top-menu-text">Sign In</span></a></li>
            <li ><a [routerLink]="['/wishlist']"><span class="lnr lnr-heart"></span><span class="top-menu-text">Saved ({{ wishList.length }})</span></a></li>
            <li *ngIf="showMegamenu">
              <ng-container *ngIf="compareItemsCount > 0">
                <a [routerLink]="['/compare']">
                  <span class="lnr lnr-balance"></span>
                  <span class="top-menu-text">Compare ({{compareItemsCount}})</span>
               </a>
              </ng-container>
              <ng-container *ngIf="compareItemsCount == 0">
               
                  <span class="lnr lnr-balance"></span>
                  <span class="top-menu-text">Compare ({{compareItemsCount}})</span>
               
              </ng-container>
              
           </li>
            </ul>
      </div>
      <div class="main-menu-inner">
        <div class="menu-left">
          <div class="icon-nav">
            <div class="box">
              <div class="box-content credit_apply">
                <i class="lnr lnr-credit-card" aria-hidden="true"></i>
                  <a [routerLink]="['/apply-for-credit']" >Apply for Credit</a>
              </div>
        
              <div class="box-content payment_apply">
                <i class="lnr lnr-cash-dollar" aria-hidden="true"></i>
                <a [routerLink]="['/payments-online']" >Make a Payment</a>
              </div>
              <div class="box-content payment_apply">
                <i class="lnr lnr-graduation-hat"></i>
                <a  [routerLink]="['/daniels-blog']"> Blog</a>
              </div>
          </div>
            
          </div>
        </div>
        <div class="headerlogo">
            <a [routerLink]="['/']">
                <img [src]="themeLogo" class="img-fluid logo" alt="logo">
            </a>
        </div>
        <div class="menu-right">
            <app-settings></app-settings>
        </div>
      </div>
    </div>
    <div class="app-menu border-section light-bg">
      <div class="container-fluid">
        <div class="main-nav-center">
            <app-menu *ngIf="showMegamenu"></app-menu>
          </div>
      </div>
    </div>
  </div>
</header>
<!-- header end -->