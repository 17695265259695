import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject, EventEmitter, Output } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Cart } from '../../../classes/cartGraphQl';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit, OnDestroy  {
  
  @Input() cart: Cart = {};
  @Input() wellsFargoPopupInfo;

  @ViewChild("orderSummary", { static: false }) OrderSummary: TemplateRef<any>;

  @Output() buttonClicked  : EventEmitter<any> = new EventEmitter<any>();
  
  public closeResult: string;
  public modalOpen: boolean = false;
  public modalRef: NgbModalRef;
  public date;
  public wellfargoConsentChecked = false;
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal, public productService: ProductService) { }

  ngOnInit(): void {
    this.date = new Date();
  }

  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalRef = this.modalService.open(this.OrderSummary, { 
        size: 'md',
        ariaLabelledBy: 'order-summary',
        centered: true,
        windowClass: 'OrderSummary' 
      });
      this.modalRef.result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

  closeModal() {
    this.modalRef.close(); 
  }  

  goBack() {
    //this.modalRef.close();    
    this.buttonClicked.emit('goBack');    
  }

  placeOrder() {
    this.buttonClicked.emit('placeOrder');
  }

  print() {
    // const printContents = document.getElementById('printOrderSummary').innerHTML;
    // const originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    window.print();
    // document.body.innerHTML = originalContents;
    // window.close();
  }
}
