<ng-template class="theme-modal" #requestShowing let-modal>
    <div class="modal-content-inner">
        <div class="modal-header">
            <img
                alt="logo"
                src="assets/images/danielslogo.jpeg"
            >
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="title-block request-title">
                <h4>Request a Showing in your Local Daniel's Store</h4>
            </div>
            <form *ngIf="!isSaved" [formGroup]="requestShowingForm" (ngSubmit)="submit()">
                <div class="form-group">
                    <label for="name">Name:</label>
                    <input id="name" class="form-control" formControlName="customer_name" placeholder="Enter name" type="text">
                    <div *ngIf="formSubmitted && requestShowingForm.controls.customer_name.errors?.required" class="text text-danger">
                        Name is required
                    </div>
                    <div *ngIf="requestShowingForm.controls.customer_name.touched && requestShowingForm.controls.customer_name.errors?.email" class="text text-danger">
                            Invalid Name
                    </div>
                </div>
                <div class="form-group">
                    <label for="email">Email address:</label>
                    <input type="email" class="form-control" formControlName="email" placeholder="Enter email" id="email">
                    <div *ngIf="formSubmitted && requestShowingForm.controls.email.errors?.required" class="text text-danger">
                        Email is required
                    </div>
                    <div *ngIf="requestShowingForm.controls.email.touched && requestShowingForm.controls.email.errors?.email" class="text text-danger">
                            Invalid Email
                    </div>
                </div>
                <div class="form-group">
                    <label for="pwd">Phone:</label>
                    <input type="text" class="form-control" formControlName="phone" placeholder="Enter phone number" id="pwd">
                    <div *ngIf="formSubmitted && requestShowingForm.controls.phone.errors?.required" class="text text-danger">
                        Phone number is required
                    </div>
                </div>
                <div class="form-group">
                    <label for="store">Store</label>
                    <select formControlName="store" class="form-control">
                        <option value="select" selected>--Select Store--</option>
                        <option *ngFor="let store of stores" value={{store.value}}>
                            {{store.name}}
                        </option>
                    </select>
                    <div *ngIf="formSubmitted && requestShowingForm.controls.store.errors?.required" class="text text-danger">
                        Store is required
                    </div>
                </div>
                <button type="submit" class="btn btn-solid">Submit</button>
            </form>
            <div *ngIf="isSaved">
                <h4>
                    {{ message }}
                </h4>        
            </div>
        </div>
    </div>
</ng-template>