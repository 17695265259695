<div class="find-it-store" id="find-store">
    <button type="button" class="close" aria-label="Close" (click)="dismissAll()">
        <span aria-hidden="true">&times;</span>
      </button>
    <div class="storeSearchList" *ngIf="!displayTransferShowing && !displayRequestShowing">
        
        <div class="title">
            <h4>Find in Store</h4>
        </div>
        <form class="finditSearchForm" [formGroup]="finditSearchForm" (ngSubmit)="onSubmitSearchStore()">
        
           <div class="form-group">
                    <a (click)="getUserLocation();" href="javascript:void(0);" class="btn use-location find-it-store-btn btn-solid">
                        <i class="fa fa-map-marker mr-2" aria-hidden="true"></i>
                          USE MY LOCATION
                    </a>
            </div>
        
        <p class="or-text mb-0"><b>-OR-</b></p>
        <div class="form-group zip-group ">
            <input formControlName="zip"  class="zipcode form-control valid" type="tel" name="zip" maxlength="10" placeholder="Zip/Postal Code" value="" inputmode="numeric" pattern="[0-9]*" aria-invalid="false"
            [ngClass]="{ 'is-invalid': submitted && getFindSearch.zip.errors }" 
            >
            <select formControlName="miles" name="range"  class="form-control productStoreRange"
            >
              <option selected value="50" >50 Miles</option>
              <option value="10" >10 Miles</option>
              <option value="20">20 Miles</option>
              <option value="40">40 Miles</option>						
              <option value="100">100 Miles</option>
            </select>
            <div *ngIf="submitted && getFindSearch.zip.errors" class="invalid-feedback">
                <div *ngIf="getFindSearch.zip.errors.required">zip is required</div>
                <div *ngIf="getFindSearch.zip.errors.pattern">Invalid zipcode / Postal code</div>
            </div>
            <div *ngIf="submitted && getFindSearch.miles.errors" class="invalid-feedback">
                <div *ngIf="getFindSearch.miles.errors.required">miles is required</div>
            </div>
            
        </div>
        <div class="form-group">
    
			<button   class="btn btn-solid find-it-store-btn">Find</button>
    </div>
    </form>

    
      
      <div class="storeList" id="storeList" *ngIf="storeList">
            <div class="list-store-container" >
                <ul>
                    <li *ngFor="let store of storeList" class="row">
                        <div class="tag-store col-md-2">
                            <p class="distance-marker">
                                <i class="fa fa-map-marker"></i>
                                    {{getDistance(store.distance)}}
                            </p>
                        </div>

                        <div class="tag-content col-md-5">
                            <p class="outofstock" *ngIf="checkQuantity(store) == false" >
                                Out Of Stock
                             </p>
                             <p class="instock" *ngIf="checkQuantity(store) == true">
                                Avaliable
                             </p>
                             <h5>
                                {{store.city}}, {{store.state}}  {{store.zipcode}}
                             </h5>
                             <p> {{store.address}} </p>
                             <p> {{store.city}}, {{store.state}}  {{store.zipcode}}</p>

                             <button *ngIf="checkQuantity(store) == true" (click)="showRequestShowing(store,1)" class="btn btn-solid find-it-store-btn">Request Showing</button>
                             <button *ngIf="checkQuantity(store) == false"  (click)="transferandShowing(store, 2);" class="btn btn-solid find-it-store-btn">Request Transfer & Showing</button>
                        </div>

                        <div class="tag-content-two col-md-5 col-lg-4">
                          <h5>Store Hours</h5>
                          <div class="table-responsive table-striped">
                            <table class="table storeHours" *ngIf="getStoreHours(store).data.length">
                                <tbody id="storeHours" >
                                    <tr *ngFor="let storedata of getStoreHours(store).data;">
                                        <td>{{storedata.day}}:</td>
                                        <td>{{storedata.time}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <ng-component *ngIf="!getStoreHours(store).data.length">
                                <p>Store temporly Closed</p>
                            </ng-component>
                          </div>
                          
                          <div class="store-link">
                            <a href="tel:{{store.phone}}">{{store.phone}}</a>
                            <a target="_blank" [routerLink]="['stores',  store.rewrite_request_path]">Store Direction</a>
                          </div>
                        </div>
                    </li>
                </ul>
            </div>
              
      </div>
      <div  *ngIf="storeNotAvaliable" class="find-store-error" >
        <p>Sorry, there are no stores in your area. Please adjust the mile radius of your search. <a [routerLink]="['/storelocator/']">View all stores</a></p>
      </div>

        

      
      </div>
            <div class="displayTransferShowing" *ngIf="displayTransferShowing">
                <div class="displayTransferShowing">
                    <div class="form-header">
                      <button (click)="backToSearchPage();" class="back-to-searchlist btn">
                          <i class="fa fa-arrow-left" ></i> Back
                      </button>
                    </div>
                          
                          <div class="card-fuild">
                              
                              <div class="card-body">
                                  <h4 class="card-header-fuild">Request Transfer & Showing</h4>
                                  <form class="open-form"  [formGroup]="transferShowingForm" (ngSubmit)="transferShowingFormSubmit()">
                                      <div class="form-group">
                                          <label class="form-label">Full Name*</label>
                                          <input  type="text" formControlName="transferShowinName" class="form-control" [ngClass]="{ 'is-invalid': submitted && getRATF.transferShowinName.errors }" />
                                          <div *ngIf="submitted && getRATF.transferShowinName.errors" class="invalid-feedback">
                                              <div *ngIf="getRATF.transferShowinName.errors.required">Username is required</div>
                                          </div>
                                      </div>
                                      <div class="form-group">
                                          <label class="form-label">Email Address*</label>
                                          <input  type="email" formControlName="transferShowinEmail" class="form-control" [ngClass]="{ 'is-invalid': submitted && getRATF.transferShowinEmail.errors }" />
                                          <div *ngIf="submitted && getRATF.transferShowinEmail.errors" class="invalid-feedback">
                                              <div *ngIf="getRATF.transferShowinEmail.errors.required">Password is required</div>
                                              <div *ngIf="getRATF.transferShowinEmail.errors.pattern">Invalid Email</div>
                                          </div>
                                      </div>
                                      <div class="form-group">
                                          <label class="form-label">Phone Number*</label>
                                          <input   type="text" formControlName="transferShowinPhone" class="form-control" [ngClass]="{ 'is-invalid': submitted && getRATF.transferShowinPhone.errors }" />
                                          <div *ngIf="submitted && getRATF.transferShowinPhone.errors" class="invalid-feedback">
                                              <div *ngIf="getRATF.transferShowinPhone.errors.required">Phone Number is required</div>
                                              <div *ngIf="getRATF.transferShowinPhone.errors.pattern">Invalid Phone Number</div>
                                          </div>
                                      </div>
                                      <div class="form-group">
                                          <button  class="btn btn-solid find-it-store-btn">
                                              Submit
                                          </button>
                                          
                                      </div>
                                  </form>
                              </div>
                          </div>
                </div>
            </div>

            <!--Request Showing---->
            <div class="displayRequestShowing" *ngIf="displayRequestShowing">
                <div class="displayRequestShowing">
                    <div class="form-header">
                      <button (click)="backToSearchPage();" class="back-to-searchlist btn">
                          <i class="fa fa-arrow-left" ></i> Back
                      </button>
                    </div>
                          
                          <div class="card-fuild">
                              
                              <div class="card-body">
                                  <h4 class="card-header-fuild">Request  Showing</h4>
                                  <form [formGroup]="requestShowingForm" (ngSubmit)="requestShowingFormSubmit()">
                                      <div class="form-group">
                                          <label class="form-label">Full Name*</label>
                                          <input  type="text" formControlName="requestShowingName" class="form-control" [ngClass]="{ 'is-invalid': submitted && getRSF.requestShowingName.errors }" />
                                          <div *ngIf="submitted && getRSF.requestShowingName.errors" class="invalid-feedback">
                                              <div *ngIf="getRSF.requestShowingName.errors.required">Name is required</div>
                                          </div>
                                      </div>
                                      <div class="form-group">
                                        <label class="form-label">Email Address*</label>
                                          <input type="email" formControlName="requestShowingEmail" class="form-control" [ngClass]="{ 'is-invalid': submitted && getRSF.requestShowingEmail.errors }" />
                                          <div *ngIf="submitted && getRSF.requestShowingEmail.errors" class="invalid-feedback">
                                              <div *ngIf="getRSF.requestShowingEmail.errors.required">Email is required</div>
                                              <div *ngIf="getRSF.requestShowingEmail.errors.pattern">Invalid Email</div>
                                          </div>
                                      </div>
                                      <div class="form-group">
                                         <label class="form-label">Phone Number*</label>
                                          <input   type="text" formControlName="requestShowingPhone" class="form-control" [ngClass]="{ 'is-invalid': submitted && getRSF.requestShowingPhone.errors }" />
                                          <div *ngIf="submitted && getRSF.requestShowingPhone.errors" class="invalid-feedback">
                                              <div *ngIf="getRSF.requestShowingPhone.errors.required">Phone Number is required</div>
                                              <div *ngIf="getRSF.requestShowingPhone.errors.pattern">Invalid Phone Number</div>
                                          </div>
                                      </div>

                                       <div class="form-group">
                                            <!--<input [(ngModel)]="model"  (focus)="d.toggle()" ngbDatepicker #d="ngbDatepicker"  placeholder="Appointment Date*"  type="text" formControlName="requestShowingDate" class="form-control" [ngClass]="{ 'is-invalid': submitted && getRSF.requestShowingDate.errors }" /> -->
                                            <label class="form-label">Appointment Date*</label>
                                            <input   placeholder="Appointment Date*"   [min]="minDate"  type="date"  formControlName="requestShowingDate" class="form-control" [ngClass]="{ 'is-invalid': submitted && getRSF.requestShowingDate.errors }" /> 
                                            <div *ngIf="submitted && getRSF.requestShowingDate.errors" class="invalid-feedback">
                                                <div *ngIf="getRSF.requestShowingDate.errors.required">Appointment Date is required</div>                                            
                                            </div>
                                        </div>

                                    <div class="form-group">
                                        <label class="form-label">Appointment Time*</label>
                                        <input  placeholder="Appointment Time*" type="time" formControlName="requestShowingTime" class="form-control" [ngClass]="{ 'is-invalid': submitted && getRSF.requestShowingTime.errors }" />
                                        <div *ngIf="submitted && getRSF.requestShowingTime.errors" class="invalid-feedback">
                                            <div *ngIf="getRSF.requestShowingTime.errors.required">Appointment Time is required</div>
                                            
                                        </div>
                                    </div>
                                    
  
                                      <div class="form-group">
                                          <button  class="btn btn-solid find-it-store-btn">
                                              Submit
                                          </button>
                                          
                                      </div>
                                  </form>
                              </div>
                          </div>
                </div>
            </div>
      </div>