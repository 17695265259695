import { Component, OnInit, Input, HostListener } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CMSService } from '../../services/cms.service';
import * as $ from 'jquery';
import { AccountService } from '../../services/account.service';
import { ProductService } from '../../services/product.service';
import { WishList } from '../../classes/wishlist';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {  Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { GeneralService } from '../../services/general.service';
@Component({
  selector: 'app-header-three',
  templateUrl: './header-three.component.html',
  styleUrls: ['./header-three.component.scss']
})
export class HeaderThreeComponent implements OnInit {
  RiddlesStatic = environment;
  @Input() class: string = 'header-2';
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = false; // Default True
  @Input() sticky: boolean = false; // Default false
  messageFromDanielsJewelers;
  showMegamenu = true;
  public stick: boolean = false;
  public wishList: WishList[] = [];

  constructor(
    public cmsService: CMSService, private accountService: AccountService,
    public productService: ProductService,
    private router: Router,
    public generalService: GeneralService,
    private route: ActivatedRoute
  ) {}


  ngOnInit(): void {
    const messageFromDanielsIdentifier = 'new_message_from_daniels_jewelers';
    /**
     * If the URL ends with .html removing .html from the url
     */
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          let urlToNavigate = this.router.url;
          let htmlExtension = ".html"
          if(urlToNavigate.endsWith(htmlExtension)) {
            urlToNavigate = urlToNavigate.substring(0, urlToNavigate.length - htmlExtension.length);
            this.router.navigate([urlToNavigate])
            return;
          }
        }
      }
    );
    // this.cmsService.getCMSBlockContent(`"${messageFromDanielsIdentifier}"`).subscribe((data: any) => {
    //   if (data) {
    //       this.messageFromDanielsJewelers = this.cmsService.getCmsContentByIdentifier(data, messageFromDanielsIdentifier)[0].content;
    //       const html = this.decodeHTML(this.cmsService.getCmsContentByIdentifier(data, messageFromDanielsIdentifier)[0].content);
    //       $('#promotion_top_bar').empty();
    //       $('#promotion_top_bar').append(html);

    //   }
    // });


    this.accountService.loginUser.subscribe(status => {
       if (status) {
        this.productService.getWishList().subscribe(response => {
          this.wishList = response.wishList;
        });
        this.productService.wishlistItems.subscribe(response => {
          this.productService.setWishList(response);
        });
       }else {
         this.wishList = [];
       }
     });

    this.generalService.isCheckoutPage.subscribe((data) => {
      if (data) {
        this.showMegamenu = false;
      }else {
        this.showMegamenu = true;
      }
  });
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 300 && window.innerWidth > 400) { 
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  get compareItemsCount() {
    return JSON.parse(localStorage['compareItems'] || '[]').length;
  }
  
  get userLoggedIn() {
    if (localStorage["customerToken"]) {
      return true;
    }
    return false;
  }

  logOut() {    
    this.accountService.logout();
  }
  decodeHTML(html) {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }
}
