import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StorelocatorService } from '../../../../pages/storelocator/storelocator.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from '../../spinner/spinner.service';
import {NgbDateStruct, NgbCalendar,  NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-find-it-instore',
  templateUrl: './find-it-instore.component.html',
  styleUrls: ['./find-it-instore.component.scss']
})
export class FindItInstoreComponent implements OnInit {
  @Input() productsku;
  finditSearchForm: FormGroup;
  transferShowingForm: FormGroup;
  requestShowingForm: FormGroup;
  submitted = false;
  storeList: any;
  storeNotAvaliable = false;
  displayTransferShowing = false;
  displayRequestShowing = false;
  selectedStore = null;
  model: NgbDateStruct;
  minDate = null;
  constructor(
    private toastrService: ToastrService,
    public storelocatorService: StorelocatorService,
    private formBuilder: FormBuilder,
    public spinner: SpinnerService,
    private calendar: NgbCalendar,
    public modalService: NgbModal
  ) {
    }

  ngOnInit(): void {
    this.finditSearch();
    this.transferShowingFormInit();
    this.requestShowingFormInit();

    const today  =  new Date();
    const date = `0${today.getDate()}`;
    const month = `0${today.getMonth() + 1}`;
    const year = today.getFullYear();
    this.minDate = `${year}-${month.slice(-2)}-${date.slice(-2)}`;
  }

  dismissAll() {
    this.modalService.dismissAll();
  }
  finditSearch() {
    this.finditSearchForm = this.formBuilder.group({
      zip: ['',
       Validators.required
      ],
      miles: ['50', Validators.required]
  });
  }

  transferShowingFormInit() {
    this.transferShowingForm = this.formBuilder.group({
      transferShowinName: ['',
       Validators.required
      ],
      transferShowinEmail: ['',
       [
         Validators.required,
         Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
      ]
    ],
    transferShowinPhone: ['',
     [
       Validators.required,
       Validators.pattern('[0-9()+-_ ]{10,20}$')
      ]
    ],
  });
  }
  requestShowingFormInit() {
    this.requestShowingForm = this.formBuilder.group({
      requestShowingName: ['',
       Validators.required
      ],
      requestShowingEmail: ['',
       [
         Validators.required,
         Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
      ]
    ],
    requestShowingPhone: ['',
     [
       Validators.required,
       Validators.pattern('[0-9()+-_ ]{10,20}$')
      ]
    ],
    requestShowingDate: ['', [Validators.required]],
    requestShowingTime: ['', [Validators.required ]],
  });
  }
  get getFindSearch() { return this.finditSearchForm.controls; }
  get getRATF() { return this.transferShowingForm.controls; } // request and transfer form
  get getRSF() { return this.requestShowingForm.controls; } // request showing form
  requestShowingFormSubmit() {
    this.submitted = true;
    if (this.requestShowingForm.invalid) {
            return;
        }
    if (!this.selectedStore) {
            return;
        }
    const store =  this.selectedStore;

    //https://amstaging.danielsjewelers.com/Home/PrivateShowing/
    const data = {
              sku: this.productsku,
              name: this.getRSF.requestShowingName.value,
              email: this.getRSF.requestShowingEmail.value,
              phone: this.getRSF.requestShowingPhone.value,
              store_email: store.email,
              store_id: store.storelocator_id,
              date: this.getRSF.requestShowingDate.value,
              time: this.getRSF.requestShowingTime.value,
              api: true
            };

           // console.log(data);
           // return false;
    this.spinner.startLoader();
    this.storelocatorService.requestShowing(data).subscribe((res) => {
          this.spinner.stopLoader();
          if (res.status == '1') {
            this.toastrService.success(res.message);
            this.selectedStore = null;
            this.displayRequestShowing = false;
            this.requestShowingForm.reset();
          } else {
            this.toastrService.error(res.message);
          }
        },
        error => {
          this.toastrService.error('Please try again later.');
          this.spinner.stopLoader();
        });

  }
  transferShowingFormSubmit() {
    this.submitted = true;
    if (this.transferShowingForm.invalid) {
            return;
        }
    if (!this.selectedStore) {
            return;
        }
    const store =  this.selectedStore;
    const data = {
          sku: this.productsku,
          name: this.getRATF.transferShowinName.value,
          email: this.getRATF.transferShowinEmail.value,
          phone: this.getRATF.transferShowinPhone.value,
          store_email: store.email,
          store_id: store.storelocator_id,
          store_name: store.store_name,
          api: true
        };
    this.spinner.startLoader();
    this.storelocatorService.requestAndTransfer(data).subscribe((res) => {
          this.spinner.stopLoader();
          if (res.status == '1') {
            this.toastrService.success(res.message);
            this.selectedStore = null;
            this.displayTransferShowing = false;
            this.transferShowingForm.reset();
          } else {
            this.toastrService.error(res.message);
          }
        },
        error => {
          this.toastrService.error('Please try again later.');
          this.spinner.stopLoader();
        });
  }

  backToSearchPage() {
    this.displayTransferShowing = false;
    this.displayRequestShowing = false;
    this.selectedStore =  null;
    document.querySelector('.find-it-instore-modal').classList.remove('form-open');
  }
  onSubmitSearchStore() {
    
    this.submitted = true;
    if (this.finditSearchForm.invalid) {
            return;
        }
    this.getLatLongByAddress(this.getFindSearch.zip.value).then((res: any) => {
      const params = {
        type: 'findStore',
        radius: this.getFindSearch.miles.value,
        lat: res.lat,
        lng: res.lng,
        productsku: this.productsku
      };
      this.getStores(params);
     });
  }

 
  getLatLongByAddress(address) {
    const geocoder =  new window['google'].maps.Geocoder();
    const locationAddress = new Promise((resolve, reject) => {
      geocoder.geocode({ address   },
          (res, sta) => {
        if (sta === window['google'].maps.GeocoderStatus.OK) {
         const cord = res[0].geometry.location;
         const cordinates = {
          lat: cord.lat(),
          lng: cord.lng()
        };
         resolve(cordinates);
        }else {
          reject(null);
        }
        });
    });
    return locationAddress;
  }
  
  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const  param = {
          type: 'findStore',
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
          radius: 500,
          productsku: this.productsku
        };
        this.getStores(param);
    }, (error) => {
        if (error.code == 1){
           this.toastrService.error(error.message);
        }
    });
    }
  }

  getStores(params) {
    this.storeList = null;
    this.storeNotAvaliable = false;
    this.spinner.startLoader();
    this.storelocatorService.getStores(params).subscribe((res) => {
            if (res.length) {
              //this.storeList = res;
              this.filterbyQty(res);
            } else {
              this.storeNotAvaliable = true;
            }
            this.spinner.stopLoader();
            this.submitted = false;
        },
        error => {
          this.toastrService.error(error);
          this.spinner.stopLoader();
          this.submitted = false;
        });
  }
  filterbyQty(res) {
    // this.storeList = res;
    let isOutOfStock = true;
    const data = [];
    res.forEach(store => {
      // tslint:disable-next-line:radix
      if (parseInt(store.Quantity) >= 1 ) {
        data.push(store);
      }else {
        if (isOutOfStock) {
          data.push(store);
          isOutOfStock = false;
      }
      }
    });

    this.storeList = data;
  }
  getDistance(distance) {
      const distanceFloat = parseFloat(distance);
      return `${distanceFloat.toFixed(1)} MI`;
  }
  checkQuantity(store) {
      return (parseInt(store.Quantity, 10) >= 1) ? true : false;
  }
  showRequestShowing(store, type){
    document.querySelector('.find-it-instore-modal').classList.add('form-open');
    this.displayRequestShowing = true;
    this.selectedStore =  store;
  }
  transferandShowing(store, type) {    
       document.querySelector('.find-it-instore-modal').classList.add('form-open');
      this.displayTransferShowing = true;
      this.selectedStore =  store;
  }
  
  getStoreHours(store) {
    const storeHours: any = {};
    const self = this;
    storeHours.data = [];
    if (store.sunday_status === '1') {
      storeHours.data.push({
        day : 'Sun',
        time: `${self.tConvert(store.sunday_open)} - ${self.tConvert(store.sunday_close)}`
      });
    }
    if (store.monday_status  === '1') {
      storeHours.data.push({
        day : 'Mon',
        time: `${self.tConvert(store.monday_open)} - ${self.tConvert(store.monday_close)}`
      });
    }
    if (store.tuesday_status   === '1') {
      storeHours.data.push({
        day : 'Tue',
        time: `${self.tConvert(store.tuesday_open)} - ${self.tConvert(store.tuesday_close)}`
      });
    }
    if (store.wednesday_status    === '1') {
      storeHours.data.push({
        day : 'Wed',
        time: `${self.tConvert(store.wednesday_open)} - ${self.tConvert(store.wednesday_close)}`
      });
    }
    if (store.thursday_status     === '1') {
      storeHours.data.push({
        day : 'Thur',
        time: `${self.tConvert(store.thursday_open)} - ${self.tConvert(store.thursday_close)}`
      });
    }
    if (store.friday_status      === '1') {
      storeHours.data.push({
        day : 'Fri',
        time: `${self.tConvert(store.friday_open)} - ${self.tConvert(store.friday_close)}`
      });
    }
    if (store.saturday_status       === '1') {
      storeHours.data.push({
        day : 'Sat',
        time: `${self.tConvert(store.saturday_open)} - ${self.tConvert(store.saturday_close)}`
      });
    }
    return storeHours;
  }

  tConvert(time) {
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }
}
