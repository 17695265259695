import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringreplace'
})
export class StringReplacePipe implements PipeTransform {
  constructor() {}
  public transform(url: any) {
    //return this.replaceAll(value, find, replace);
    url = url.replace(/>/g, '_');
    url = url.replace(/-/g, '--');
    url = url.replace(/ /g, '-');
    return url;
  }

   replaceAll(str, find, replace) {
    const escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    return str.replace(new RegExp(escapedFind, 'g'), replace);
  }

}
