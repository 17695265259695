<ng-container *ngIf="iframeSrc">
    <button type="button" class="close" aria-label="Close" (click)="dismissAll()">
        <span aria-hidden="true">&times;</span>
      </button>
      <iframe #tryOnLoaderIframeRef id="try-on-loader-iframe" [src]="iframeSrc" allow="camera" style="border:0; width: 100%;height:100%;"
    (load)="onLoad()">
      
    </iframe>
    
</ng-container>
<ngx-spinner bdColor = "white" size = "medium" color = "rgba(100,149,237, .8)" type = "square-spin" 
      [fullScreen] = "false"><p style="color: rgba(100,149,237, .8)" > Loading... </p>
</ngx-spinner> 


