import { Component, OnInit, Input } from '@angular/core';
import { AccountService } from '../../services/account.service';
import { response } from 'express';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CMSService } from '../../services/cms.service';
import { element } from 'protractor';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  isLogin = false;
  public today: number = Date.now();
  // public newsletterEmail: string;
  //public successMessage: string;
  subscriptionLoading = false;
  subscriptionSubmitted = false;
  subscriptionForm: FormGroup;
  footerData: any;
  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    public cmsService: CMSService
  ) {
    this.accountService.loginUser.subscribe(status => {
      if (status) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
    });

  }

  ngOnInit(): void {
    this.subscriptionForm = this.formBuilder.group({
      subscriberEmail: ['', [Validators.required, Validators.email]],
    });

    const footerCol1 = 'new_footer_col_1';
    const footerCol2 = 'new_footer_col_2';
    const footerCol3 = 'new_footer_col_3';
    const footerCol4 = 'new_footer_col_4';

    // this.cmsService.getCMSBlockContent(`
    //   "${footerCol1}",
    //    "${footerCol2}",
    //     "${footerCol3}",
    //     "${footerCol4}"
    //     `).subscribe(async (data: any) => {
    //   this.footerData = data;


    //   setTimeout(() => {
    //     document.querySelectorAll('.footer-col').forEach((elemeny) => {
    //       elemeny.addEventListener('click', (event) => {
    //         if (elemeny.classList.contains('active')) {
    //           elemeny.classList.remove('active');
    //         } else {
    //           elemeny.classList.add('active');
    //         }
    //       });
    //     });
    //   }, 3000);
    // });
  }

  get f() { return this.subscriptionForm.controls; }

  subscribeNewsletter() {
    this.subscriptionSubmitted = true;
    if (this.subscriptionForm.invalid) {
      return;
    }
    this.subscriptionLoading = true;

    this.accountService.subscribeNewsletter(this.f.subscriberEmail.value).subscribe((res: any) => {
      this.toastrService.success(res.msg);
      this.accountService.memberSubscribeNewsletter(this.f.subscriberEmail.value).subscribe((res: any) => {
        console.log('response', res);
        if (res.status == 1) {
          // this.toastrService.success(res.message);
          this.subscriptionForm.reset();
        } else {

        }
        this.subscriptionLoading = false;
        this.subscriptionSubmitted = false;
      }, (error) => {
        this.subscriptionLoading = false;
        this.subscriptionSubmitted = false;
      });

    },
      error => {
        const errorMsg = 'something went wrong. please try again later!';
        this.toastrService.error(errorMsg);
        this.subscriptionLoading = false;
        this.subscriptionSubmitted = false;
      });
    /*
    
    this.accountService.subscribeNewsletter(this.f.subscriberEmail.value)
    .subscribe((response) => { 
    
    }); */
  }
}
