import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss']
})
export class GeneralModalComponent implements OnInit {
  @Input() fromParent;
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}
