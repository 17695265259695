import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlkey'
})
export class UrlkeyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let url =  value.replace(/^.*\/\/[^\/]+/, '');
    url = url.split('.').slice(0, -1).join('.');
    url = url.slice(1);
    return url;
  }

}
