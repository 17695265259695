<div class="product-box-inner" *ngIf="!loader">
  <div class="img-wrapper">
   
    <!--<ng-container *ngIf="product?.Clearanceprice">
      <div class="lable-block">
        <span> {{ product?.Clearanceprice  | discountPercentage:  product?.Price | number:'1.0-0'}}% off </span> 
      </div>
    </ng-container>-->
    <a (click)="productClick('')" id="image-url" [routerLink]="['/',this.product?.Urlkey]" (mousedown)="onStartRecording($event)" >
      <img
        defaultImage="'assets/images/product/placeholder.jpg'" 
        [src]="product.Image" 
        class="img-fluid lazy-loading" 
        alt="" />
    </a> 
    <!-- <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/product/left/sidebar/', product.url]">
        <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading" alt="{{ product.images[1].alt }}">
      </a>
    </div> -->
    <!-- <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src">
        </a>
      </li>
    </ul> -->
    <!-- <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"
        *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)"
        *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div> -->
  </div>
  <div class="product-detail">
    <div class="product-prices">
          <!--<div class="sale-price">
             <span  [ngClass]="product?.OnSale ? 'active': 'inactive'">SALE:</span>
             <span>{{ product?.Price | currency:currency?.currency:'symbol' }}</span>
           </div>-->

           <ng-container  *ngIf="!product?.Clearanceprice">             
              <div class="sale-price price" [ngClass]="(product?.Onsale == '1' && product?.Price !== product?.Saleprice) ? 'onsale-active': 'onsale-inactive'">
                <span class="sale-tag">SALE </span>
                <span>{{ product?.Saleprice | currency:currency?.currency:'symbol' }}</span>
              </div>
           </ng-container>

          <ng-container  *ngIf="product?.Clearanceprice">
            <div class="price clearance-price">
              <span  *ngIf="product?.Clearanceprice"> Clearance {{ product?.Clearanceprice  | currency:currency?.currency:'symbol' }}</span>
            </div>
         </ng-container>

         <div class="original-price" *ngIf="product?.Price  != product?.Clearanceprice  && product?.Price != product?.Saleprice">
             <span> ORIG:   {{ product?.Price  | currency:currency?.currency:'symbol' }}</span>
             <ng-container  *ngIf="!product?.Clearanceprice && product?.Saleprice != product?.Price">
              <span> ({{ product?.Saleprice  | discountPercentage:  product?.Price | number:'1.0-0'}}% savings)</span> 
             </ng-container>

             <ng-container  *ngIf="product?.Clearanceprice">
              <span *ngIf=" product?.Clearanceprice != product?.Price"> ({{ product?.Clearanceprice  | discountPercentage:  product?.Price | number:'1.0-0'}}% savings) </span> 
            </ng-container>
        </div>
          <ng-container  *ngIf="product?.Clearanceprice">
             <app-aslowasprice *ngIf="asLowas(product.Clearanceprice)"  [asLowAsPrice]="product.Clearanceprice" page='list'></app-aslowasprice>          
         </ng-container>
         <ng-container  *ngIf="!product?.Clearanceprice">
          <app-aslowasprice *ngIf="asLowas(product.Saleprice)"  [asLowAsPrice]="product.Saleprice" page='list'></app-aslowasprice>  
         </ng-container>
       
     <!-- <span *ngIf="product?.Price  != product?.Saleprice">Orig: {{ product?.Price | currency:currency?.currency:'symbol' }}</span>
      <span class='sale-price' *ngIf="!product?.Clearanceprice"> Sale: {{ product?.Saleprice  | currency:currency?.currency:'symbol' }}</span>
      <span class='sale-price clearance-price' *ngIf="product?.Clearanceprice"> Clearance: {{ product?.Clearanceprice  | currency:currency?.currency:'symbol' }}</span>
     -->
    </div>
    <div class="product-description">
      <a><h6 [innerHTML]="product?.Name | safe:'bindhtml'" ></h6></a>
    </div>
    <ng-container *ngIf="product?.Holidayship == '1'">
      <p class="list-holiday-delivery text-uppercase text-success"> &#10003; Holiday Delivery</p>
    </ng-container>
    <div class="view-button">
      <div class="additional-buttons d-flex justify-content-between">
        <button (click)="addToCompare($event)" class="d-flex align-items-center" id="compare_btn"><span class="lnr lnr-balance"></span>Compare</button>  
        <button (click)="addToWishlist()" class="d-flex align-items-center"><span class="lnr lnr-heart"></span>Save</button>  
      </div>
    </div>
  </div>
</div>
<!-- <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal> -->

