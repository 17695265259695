export const environment = {
  production: false,
  //instagram_token: 'IGQVJYTWRHOXh5V29maVhWblNBSkxvOFROSkJKNmlrS2FaWk45a2lOWC1WcWFDOV94ZADZANNldrY0I2TEcxOFFudi13TW9pLUMwZAUEwVDV5RDZAIQm5XWEwzUmFROGhZAX3kxbnBjTmpn',
  instagram_token: 'IGQVJVcGlQVmxWdC1CVEVUX0pjRXF4SnlNenR0bkh1ODVRMFFILVNITE5rLU1tTWR2QlkxelRjbTd1VkdWbm4tcHI5OG1QaFYwc0kzYVh3bm1IWXpEV01YUW9RR1VrWnh5alhWa013',
  stripe_token: 'STRIPE_PUBLISHABLE_KEY',
  instrumentationKey:'3117ae19-0ed6-4862-8eab-dd88bb91101b', //staging
  //instrumentationKey:'58fd57da-fe88-48b1-b405-4512897a73b1', //prodn

  paypal_token: 'sb',
  phonenumber: {
    number: '18884023475',
    display_string: '1-888-402-3475'
    },
    //AWS environment
    
featureCategoryImageBaseURL: 'https://ar.augmentes.com/media/catalog/category/',
    homePageBannerImageBaseURL: 'https://ar.augmentes.com/media/slide/',
    BASE_URL: 'https://ar.augmentes.com/',
    image_base_url: 'https://ar.augmentes.com/pub/media/catalog/product/',
    product_base_url: 'https://ar.augmentes.com/rest/V1/',
    api_host: 'https://ar.augmentes.com/',    
    category_image_base_url: 'https://ar.augmentes.com/media/catalog/category/',
    category_image_banner_mobile: 'https://ar.augmentes.com/',
    blog_thumb_image_base_url: 'https://ar.augmentes.com/media/resized/300x404/',
    blog_image_base_url: 'https://ar.augmentes.com/media/', 
    BASE_MEDIA_URL: 'https://ar.augmentes.com/pub/media/',
    DYO_BASE_MEDIA_URL: 'https://ar.augmentes.com/pub/media/DYO',
    PRODUCT_360IMAGE_BASE_URL: 'https://ar.augmentes.com/media/magic360',
    Google_Analytics_url: 'https://ar.augmentes.com/',
    
   // product_list_url: 'https://api.searchspring.net/api/search/search?resultsFormat=native&siteId=m9stph',



    
    graphql_url: 'graphql',    
    get_product_url: 'product/detail/',
    add_to_cart_guest_url: 'guest-carts/{0}/items',
    add_to_cart_customer_url: 'carts/mine/items', 
    address_to_cart_guest_url: 'guest-carts/{0}/shipping-information',
    address_to_cart_customer_url: 'carts/mine/shipping-information', 
    get_shiping_methods_guest_url: 'guest-carts/{0}/estimate-shipping-methods',
    get_shiping_methods_customer_url: 'carts/mine/estimate-shipping-methods',   
    get_cart_url: 'guest-carts/{0}/items',
    get_quote_id_url: 'guest-carts',
    get_order_url:'orders/',
    social_login_url:'social/login/',
    get_store_url:'Home/PrivateShowing/StoreList?lat=0.0&lng=0.0',
    request_showing_url:'rest/V1/requestShowing',       
    authorize_net_order_url:'payment/captureAmount',    
    subscribe_newsletter_url:'home/subscribeNewsLetter',
    membersubscribe_newsletter_url:'home/registerwithfishbowl',
    unsubscribe_newsletter_url:'home/unsubscribeNewsLetter',
    add_to_wish_list_url: 'wishlist/add/',
    get_from_wish_list_url: 'wishlist/product/',  
    remove_from_wish_list_url: 'wishlist/remove/', 
    compare_product_url: 'compare/product/',    
    wells_fargo_order_url: 'payment/riddlescreditcard/',
    get_all_blogs_url: 'home/getBlogDetails/',
    forgot_password_url: 'customers/password',
    reset_password_url: 'home/forgetpassword/',    
    contact_us_url: 'home/ContactUs/',
    url_rewrite_url: 'home/getUrlRewriteDetails/',
    write_log_url: 'writeLog/',    
    adminBearerToken: 'h227x8nbehc79x2qkoowo55vmkw87cod',
    addGiftMessageUrl: 'carts/{0}/gift-message',
    savePaymentDetails: 'payment/savePaymentDetails',    
    getBirthMonthOptionsUrl: 'Customer/getAttributeValues/customer_birth_month',
    getAnniversaryMonthOptionsUrl: 'Customer/getAttributeValues/customer_anniversary_month',
    registerCustomerUrl: 'customers',
    API_BASE_URL: 'https://ar.augmentes.com/rest/V1/',
   /* sunbit_key: 'YqdQLEYXLUQqwpymjhpyBtzJoTju6Cyh',
    sunbit_secret: 'F69wuULZTympq1UJj9n9hAnaXeOyYMfM',
    initializeSubitRequestUrl: 'https://api-demo.sunbit.com/epay-service/api/v1/epay',
    validateSubitRequestUrl: 'https://api.sunbit.com/epay-service/api/v1/epay?transactionId={0}&purchaseId={1}',
    sunbitLocation: 'sandbox'*/
    sunbit: {
      sunbit_key: 'YqdQLEYXLUQqwpymjhpyBtzJoTju6Cyh',
      sunbit_secret: 'F69wuULZTympq1UJj9n9hAnaXeOyYMfM',
      initializeSubitRequestUrl: 'https://api-demo.sunbit.com/epay-service/api/v1/epay',
      validateSubitRequestUrl: 'https://api-demo.sunbit.com/epay-service/api/v1/epay?transactionId={0}&purchaseId={1}',
      sunbitLocation: 'sandbox',
      sunbitMode: 'demo' 
  },
  saveDanielsCreditDetailsUrl: 'saveDanielsCreditDetails',
  //CATEGORY_API_URL: 'https://ecommerce-search.nextopiasoftware.com/return-results.php?client_id=bd22bf1275b0de1fe01715800b476b8e&json=1'
  CATEGORY_API_URL: 'https://api.nextopiasoftware.com/return-results?client_id=519d74c1bfcff7ded6f27033dae47518&json=1&res_per_page=24&refines_mode=keep&return_single_refines=1:0',
    searchAutoCompleteUrl: 'https://api.nextopiasoftware.com/return_autocomplete_jsonp_v3.php?callback=AutoComplete&cid=2b13e820449274dafb1ec68ca00af296&_=585748654',
  SYNCHRONY_MERCHANT_ID: 5348120340302090,
  SYNCHRONY_DOMAIN_URL :'https://ubuy.syf.com',
  taxJar: {
    api_url: 'https://api.sandbox.taxjar.com/v2/taxes',
    api_key: 'bf20a4cd8066ff614ad7821a747cfe2b'
},
dpayExistingCustomerBaseURL: 'https://daniels.infinitybuss.com',
    dpayExistingCutomerIframeURL: 'dpay/dp_Payment.aspx?key=',
    //dpayNewCustomerBaseURL: 'https://djoapply.danielsjewelers.com',
    dpayNewCustomerBaseURL: 'https://staging.djoapply.danielsjewelers.com',
    dpayNewBaseURL: 'https://instoreportal-associates-stage-svc.azurewebsites.net',
    dpayNewCutomerIframeURL: '(S(kvlqk14u20clejtgckqldowx))/?cartId=',

// DPay Iframe Options
dpayIframe: {
  retryCount: 2,
  retryWaitMilliSeconds: 1000
},

newFinPaymentPhone: '866-958-7664',
webarUrl:'https://dyotryon.augmentes.com/',
// webarUrl:'http://localhost:8000',
webarEnabled:true,

tryOnAugmentes:'https://arpimages.s3.us-west-1.amazonaws.com/data/TryonProducts.json',
};

