import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { ProductService } from '../shared/services/product.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-elements',
  templateUrl: './elements.component.html',
  styleUrls: ['./elements.component.scss']
})
export class ElementsComponent implements OnInit {
  
  
  constructor(private route: ActivatedRoute, private router: Router,
              public productService: ProductService,
              public toastrService: ToastrService,
              private activerouter: ActivatedRoute
              ) { }

  ngOnInit(): void {

    if (!this.router.url.includes('nsearch') && this.getQueryParamFromMalformedURL('profile_id')) {
      let q ='';
      if (this.getQueryParamFromMalformedURL('q')) {
        q = this.getQueryParamFromMalformedURL('q');
      }
      else {
        q = this.getQueryParamFromMalformedURL('keywords');
      }

      window.location.href = '/nsearch/?q=' + q + '#?' + this.router.url.substr(2, this.router.url.length - 2);
    }
    else if (this.router.url.includes('nsearch') && this.getQueryParamFromMalformedURL('q')) {

    }
    else {
      this.router.navigate(['/' + this.router.url]);
    }

    $('.column.main').on('click', '.filter-toggle', () => {
      $('.collection-filter').toggleClass('openFilterbar');

    });

    $('.column.main').on('click', '.tocompare', (e) => {
      e.preventDefault();
      e.stopPropagation();
      let sku = $(e.currentTarget).parent().find('.compare_check').attr('id');
      sku = sku.split('-')[1];
      const name = $(e.target).parents('.product-item-details').children().find('.product-item-name').text();
      this.productService.addToCompare(sku, name);
    });
    
    $('.column.main').on('click', '.towishlist', (e) => {
      e.preventDefault();
      e.stopPropagation();
      let productid = $(e.currentTarget).data('post');
      productid = productid.data.product;
      const name = $(e.target).parents('.product-item-details').children().find('.product-item-name').text();
      this.addToWishlist(productid, name);
    });

  }
  mobileSidebar() {
    $('.collection-filter').toggleClass('openFilterbar');
  }
  getQueryParamFromMalformedURL(name) {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(decodeURIComponent(this.router.url)); // or window.location.href
    if (!results) {
        return '';
    }
    return results[1] || '';
}

addToWishlist(productid, name) {
  if (!localStorage.getItem('customerToken')) {
    if (confirm('You need to login to add this item to your wishlist. Do you want to login?')) {
      const searchString = this.activerouter.snapshot.queryParamMap.get('q');
      this.router.navigate(['/pages/login'], { queryParams: { search: searchString } });
    }
  }
  else {
      this.productService.addToWishlist(productid).subscribe(response => {
      this.productService.setWishList(response);
      if (name) {
        this.toastrService.success(`Product <b>${name}</b> has been added to your Saved Items`);
      }else {
        this.toastrService.success('Item added to wishlist');
      }
    },
      error => {
        this.toastrService.error('Sorry there is an error while adding this item to wishlist');
      });
  }
}

}