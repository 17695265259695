import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams   } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { retry, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CMSService {

  BASE_URL = environment.BASE_URL;
  Google_Analytics_url = environment.Google_Analytics_url;
 
  constructor(
    private http: HttpClient
  ) { }

getLandingPageData(pageid) {
    let params = new HttpParams();
    params = params.append('query', `{
      cmsPage(identifier: "${pageid}") {
        url_key
        title
        content
        content_heading
        page_layout
        meta_title
        meta_description
        meta_keywords
        }
      }`);


    
    return this.http.get(`${this.BASE_URL}graphql`, {params})
    .pipe(
        map( res => {
          if (res) {
              return res;
          }
        })
      );
}


getCMSBlockContent(blockId) {
  let params = new HttpParams();
  params = params.append('query', `{
      cmsBlocks(identifiers: [${blockId}]) {
      items {
      identifier
      title
      content
      }
      }
    }`);



  return this.http.get(`${this.BASE_URL}graphql`, { params })
    .pipe(
      map((res: any) => {
        if (res) {
          return res.data.cmsBlocks;
        }
      })
    );
}

getCmsContentByIdentifier(data, identifier) {
  return data.items.filter(item => item?.identifier === identifier);
}

removeTags(str) {
  if ((str === null) || (str === '')) {
    return false;
  }
  else {
    str = str.toString();
  }
  return str.replace(/(<([^>]+)>)/ig, '');
}


getInfoSpecialBlockPopUpData(identifier) {
  let params = new HttpParams();
  params = params.append('query', `{
    cmsBlocks(identifiers: "${identifier}") {
    items {
    identifier
    title
    content
    }
    }
    }`);

  return this.http.get(`${this.BASE_URL}graphql`, { params })
    .pipe(
      map((res: any) => {
        if (res) {
          console.log(res);
          if (res.data.cmsBlocks.items[0].content) {
            const details = {
                title: res.data.cmsBlocks.items[0].title,
                content: this.removeTags(res.data.cmsBlocks.items[0].content)
            };
            return details;
          }
        }
      })
    );
}

getGTMScript() {
  return this.http.get(`${this.Google_Analytics_url}graphql?query=%7B%0A%20%20%20%20%20%20cmsBlocks(identifiers:%20%5B%22gtm-test%22%5D)%20%7B%0A%20%20%20%20%20%20items%20%7B%0A%20%20%20%20%20%20identifier%0A%20%20%20%20%20%20title%0A%20%20%20%20%20%20content%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D`)
}

getGTMNoScript() {
  return this.http.get(`${this.Google_Analytics_url}graphql?query=%7B%0A%20%20%20%20%20%20cmsBlocks(identifiers:%20%5B%22gtm-no-script%22%5D)%20%7B%0A%20%20%20%20%20%20items%20%7B%0A%20%20%20%20%20%20identifier%0A%20%20%20%20%20%20title%0A%20%20%20%20%20%20content%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D`)
}

}
