<div class="navigation main-navbar tm-navigation tm-top-navigation" role="navigation">
  <div class="toggle-nav" (click)="mainMenuToggle()" [class.close]="navServices?.mainMenuToggle">
    <label class="toggle-nav-icon lnr"></label>
  </div>
  <div id="mainnav">
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

      <li class="mobile-links megamenu-wrapper-parent">
        <a [routerLink]="['payments-online']">
          Make A payment
        </a>
      </li>
      <li class="mobile-links megamenu-wrapper-parent">
        <a [routerLink]="['apply-for-credit']">Apply for credit</a>
      </li>
      <li *ngIf="userLoggedIn" class="mobile-links megamenu-wrapper-parent">
        <a [routerLink]="'/pages/dashboard'">My Account</a>
      </li>
      <li *ngIf="userLoggedIn" class="mobile-links megamenu-wrapper-parent">
        <a (click)="logOut()" href="jacascript:void(0);">Sign Out</a>
      </li>
      <li *ngIf="!userLoggedIn" class="mobile-links megamenu-wrapper-parent">
        <a [routerLink]="'/pages/login'">Sign In</a>
      </li>

      <!-- <ng-container *ngFor="let menuItem of megaMenuItems.data.categoryList"> -->
        <li (click)="closeMenu();" *ngFor="let children of menuItems"
          class="level0 nav-1 first level-top  parent megamenu-wrapper-parent">
          <a [routerLink]="[children.url]">
            {{ children.name | translate }}
          </a> 
          <!-- <a *ngIf="children.name != 'Clearance'" [routerLink]="[children.url_path]">
            {{ children.name | translate }}
          </a>

          <a *ngIf="children.name == 'Clearance'" [routerLink]="[children.url_path]">
            {{ children.name | translate }}
          </a> -->
          <span class="sub-arrow" (click)="toggletNavActive($event);"></span>

          <!-- <div class="tm-megamenu megamenu-wrapper submenu">
            <div class="container">
              <div class="row mb-4">
                <div [class]="checkColum(children.children).menuCol" *ngFor="let children1 of children.children">
                  <ul class="mm-submenu level1">
                    <li>
                      <a class="level1-title" href="javascript:void(0);" style="cursor: default;">{{children1.name}}</a>

                      <ul class="mm-submenu level2">
                        <li (click)="closeMenu();" *ngFor="let children2 of children1.children">
                          <a [routerLink]="[children.url_key, children1.url_key,children2.url_key]">{{children2.name}}</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div *ngIf="children.megamenu_image" class="megamenu-image-wrapper"
                  [class]="checkColum(children.children).imageCol" [innerHTML]="children.megamenu_image">
                </div>
              </div>
              <div class="row">
                <div class="container">
                  <div class="menu-viewall">
                    <a [routerLink]="[children.url_path]">View All {{children.name}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </li>
      <!-- </ng-container> -->

      <li class="nav-custommenu compare-wishlist">
        <div class="mobile-menu-compare">
          <a *ngIf="compareItemsCount > 0" [routerLink]="['/compare']"><i class="fa-balance-scale fa"></i>
            COMPARE
          </a>
          <a *ngIf="compareItemsCount == 0" href="javascript:void(0);"><i class="fa-balance-scale fa"></i>
            COMPARE
          </a>
        </div>
        <div class="separator">&nbsp;</div>
        <div class="mobile-menu-favorites"><a [routerLink]="['/wishlist']" tabindex="0">
            <i class="fa fa-heart"></i>FAVORITES</a></div>
      </li>
    </ul>
  </div>
</div>