<div class="progress-loader" [hidden]="!loading">
    <div class="loading-spinner">
            <div class="spinner-bg">
                <img src="../../../../assets/images/Daniels/ajax-loader.gif" >
            </div>
    </div>
</div>


<div class="progress-loader-dynamic">
    
</div>