<div *ngIf="asLowAsPrice" class="aslowasprice" [ngClass]="page">
  <p class="info-page"  *ngIf="page == 'info'">
    
    <span class="low-as-price">
    ${{ asLowAs() }} / month
    </span>
      <span class="label-text">on Daniel's Credit</span>
      <span (click)="openModel();" class="fa fa-info"></span>
  </p>
  
  
  <p *ngIf="page == 'cart'">
    <span>Finance as low as </span>
    <span class="low-as-price">
    ${{ asLowAs() }} per month 
    </span>
    on Daniel's Credit! <br> <strong>99.7% of our customers are approved!</strong>
    <span (click)="openModel();" class="fa fa-info"></span>

  </p>

  <p class="page-list" *ngIf="page == 'list'">
    <span class="low-as-price">
      ${{ asLowAs() }} /month
    </span>
    on Daniel's Credit
  </p>

  <ng-container  *ngIf="page == 'checkout'">
    <p>
      <span>Finance as low as </span>
      <span class="low-as-price">
      ${{ asLowAs() }} per month.
      </span>
       <b>99.7% of our customers are approved!</b>
    </p>
    <span (click)="openModel();" class="fa fa-info"></span>
  </ng-container>

  

  
  
</div>
    