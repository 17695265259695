import { Component, OnInit } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  loading: boolean;
  constructor(
    public  spinner: SpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.isLoading.subscribe((v) => {
     // console.log(v);
     // alert(v);
      this.loading = v;
    });

    this.spinner.dynamicCreatedLoader.subscribe((v) => {
      if (v.create) {
            document.querySelector('.progress-loader-dynamic').insertAdjacentHTML('beforeend', `
            <div class="loading-spinner" id=${v.id}>
            <div class="spinner-bg">
                <img src="../../../../assets/images/Daniels/ajax-loader.gif" >
            </div>
    </div>`);
        }
      if (v.create == false) {
          const elem = document.querySelector(`#${v.id}`);
          elem.parentNode.removeChild(elem);
      }
    });
  }

 
}
