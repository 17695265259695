<ng-template class="theme-modal" #premiumWarranty let-modal>
  <div class="modal-header">
		<div class="header-image">
			<img src="../../../../../assets/images/danielslogo.jpeg" class="img-fluid">
		</div>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
	</button>
  </div>
  <div class="modal-body model-content-tag">
    
    <div class="modal-body-tag">
        <div>
           <div class="WordSection1">
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="font-size: 20.0pt; mso-bidi-font-size: 14.0pt; font-family: 'Avant Garde'; color: #7030a0;">Daniel’s Jewelers Protection Plans</span><span style="font-size: 16.0pt; mso-bidi-font-size: 14.0pt; font-family: 'Avant Garde'; color: black;"></span></p>
              <br /><br />
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="font-size: 13.0pt; font-family: 'Avant Garde'; color: #7030a0; mso-bidi-font-weight: bold;"> </span></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="font-size: 13.0pt; font-family: 'Avant Garde'; color: #7030a0; mso-bidi-font-weight: bold;">Daniel’s Jewelers Lifetime Diamond Guarantee against Original Factory Defects</span></p>
              <br />
              <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal; mso-layout-grid-align: none; text-autospace: none;" align="center"><b><span style="font-size: 12.0pt; font-family: 'Avant Garde';"> </span></b></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';">Every Daniel's diamond jewelry item is manufactured to our exacting standards. We are so confident of the quality of our jewelry that we proudly offer a LIFETIME GUARANTEE against original factory defects on every diamond you purchase from us.* <span style="mso-spacerun: yes;"> </span>Should you ever discover a factory defect in your diamond jewelry, Daniel's will repair or replace the item (at our option) at no charge to you.</span></p>
              <br />
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><b><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';"> </span></b></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';">Daniel's is proud to offer an extended service policy: our DIAMOND REGISTRY. You may purchase the Registry for a small additional charge at the same time that you buy your diamond item. Once you do, you are provided for 3 years with services not covered by our Lifetime Factory Guarantee. </span></p>
              <br />
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';"> </span></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="font-size: 13.0pt; font-family: 'Avant Garde'; color: #7030a0;">Daniel’s Jewelers Diamond Registry</span></p>
              <br />
              <p class="MsoListParagraph" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: 21.0pt; mso-add-space: auto; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><b style="mso-bidi-font-weight: normal;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: #7030a0;"> </span></b></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><i style="mso-bidi-font-style: normal;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';">Complete Diamond Protection Plan</span></i></p>
              <br />
              <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal; mso-layout-grid-align: none; text-autospace: none;" align="center"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; mso-fareast-font-family: ZapfDingbats;"> </span></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';">Our optional Diamond Protection Plan is available for purchase and provides comprehensive three-year protection for the following services for the diamond item(s) listed on the Diamond Registry from the date of purchase.</span></p>
              <br />
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';"> </span></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><b><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: black;">Diamond Protection Plan Includes:</span></b></p>
              <br />


              <div class="test">
              <p class="MsoListParagraphCxSpFirst" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l2 level1 lfo19; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Free replacement of any diamond 1/2 Carat or smaller which, in normal use, separates from its mounting and is lost</span></p>
              <p class="MsoListParagraphCxSpMiddle" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l2 level1 lfo19; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Free Re-sizing up to 2 sizes.<span style="mso-spacerun: yes;"> </span>Does not include initial sizing at time of purchase</span></p>
              <p class="MsoListParagraphCxSpMiddle" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l2 level1 lfo19; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Free tightening of stones</span></p>
              <p class="MsoListParagraphCxSpMiddle" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l2 level1 lfo19; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Free re-tipping</span></p>
              <p class="MsoListParagraphCxSpMiddle" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l2 level1 lfo19; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Free smoothing of prongs to prevent snags</span></p>
              <p class="MsoListParagraphCxSpMiddle" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l2 level1 lfo19; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Free polishing, cleaning and inspection</span></p></div>
              <br />
              <p class="MsoListParagraphCxSpLast" style="margin: 0in; margin-bottom: .0001pt; mso-add-space: auto; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';"> </span></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><b style="mso-bidi-font-weight: normal;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';">Diamond Protection Plan Excludes:</span></b></p>
              <br />
              <div class="test4">
              <p class="MsoListParagraph" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l3 level1 lfo20; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Excessive wear or obvious abuse is not covered by the Registry</span></p>
              <br /></div>

              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';"> </span></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';">The Diamond Protection Plan may be renewed every three years.<span style="mso-spacerun: yes;"> </span>Customers near a Daniel’s Jewelers store may have their diamond jewelry checked <b style="mso-bidi-font-weight: normal;"><i style="mso-bidi-font-style: normal;"><span style="text-decoration: underline;">EVERY 3 MONTHS</span></i></b> by a Daniel’s professional.</span></p>
              <br />
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';"> </span></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: black;">Daniel’s offers <span style="text-decoration: underline;">FULL TRADE-IN VALUE PLUS 5%</span> on your diamond jewelry. </span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';"><span style="mso-spacerun: yes;"> </span>The item covered by the Diamond Protection Plan may be traded in at any time toward the purchase of an item in the same merchandise category whose selling price is at least two times the trade-in amount.</span></p>
              <br />
              <p class="MsoNormal" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .5in; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><b style="mso-bidi-font-weight: normal;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: black;"> </span></b></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="font-size: 13.0pt; font-family: 'Avant Garde'; color: #7030a0;">Daniel’s Jewelers Fine Jewelry Registry</span></p>
              <br />
              <p class="MsoListParagraph" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: 21.0pt; mso-add-space: auto; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><b style="mso-bidi-font-weight: normal;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: #7030a0;"> </span></b></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><i style="mso-bidi-font-style: normal;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: black;">Complete Fine Jewelry Protection Plan</span></i></p>
              <br />
              <p class="MsoNormal" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: 4.5pt; text-indent: -4.5pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; mso-fareast-font-family: ZapfDingbats; color: black;"> </span></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; mso-fareast-font-family: ZapfDingbats; color: black;">Our optional Fine Jewelry Protection Plan is available for purchase and provides comprehensive three-year protection from normal wear and tear on your Fine Jewelry from the date of purchase.</span></p>
              <br />
              <p class="MsoNormal" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: 4.5pt; text-indent: -4.5pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: black;"> </span></p>
              <p class="MsoNormal" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: 4.5pt; text-indent: -4.5pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><b style="mso-bidi-font-weight: normal;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';">Fine Jewelry Protection Plan Includes:</span></b></p>
              <br />
              <p class="MsoNormal" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: 4.5pt; text-indent: -4.5pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><b style="mso-bidi-font-weight: normal;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';"> </span></b></p>
              <p class="MsoNormal" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: 4.5pt; text-indent: -4.5pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: black;">For KARAT GOLD CHAINS &amp; BRACELETS we will:</span></p>
              





              <div class="test1"><p class="MsoListParagraphCxSpFirst" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l3 level1 lfo20; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Repair any break that occurs with normal use</span></p>
              <p class="MsoListParagraphCxSpMiddle" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l3 level1 lfo20; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Repair any clasp that breaks with normal use</span></p>
              <p class="MsoListParagraphCxSpLast" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l18 level1 lfo17; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Clean and/or polish free of charge</span></p>
              <br /></div>


              <p class="MsoNormal" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: 22.5pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: black;"> </span></p>
              <p class="MsoNormal" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: 22.5pt; text-indent: -22.5pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><a name="_GoBack"></a><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: black;">For RINGS &amp; PENDANTS we will:</span></p>
              <p class="MsoNormal" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: 22.5pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: black;"> </span></p>
              


              <div class="test2"><p class="MsoListParagraphCxSpFirst" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l12 level1 lfo18; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Free replacement of any stone which, in normal use, separates from its mounting and is lost</span></p>
              <p class="MsoListParagraphCxSpMiddle" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l12 level1 lfo18; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Free Re-sizing up to 2 sizes.<span style="mso-spacerun: yes;"> </span>Does not include initial sizing at time of purchase</span></p>
              <p class="MsoListParagraphCxSpMiddle" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l12 level1 lfo18; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Free tightening of stones</span></p>
              <p class="MsoListParagraphCxSpMiddle" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l12 level1 lfo18; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Free re-tipping</span></p>
              <p class="MsoListParagraphCxSpMiddle" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l12 level1 lfo18; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Free smoothing of prongs to prevent snags</span></p>
              <p class="MsoListParagraphCxSpMiddle" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l12 level1 lfo18; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Free polishing, cleaning and inspection</span></p>
              <br /></div>


              <p class="MsoListParagraphCxSpLast" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: 22.5pt; mso-add-space: auto; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';"> </span></p>
              <p class="MsoNormal" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: 22.5pt; text-indent: -22.5pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><b style="mso-bidi-font-weight: normal;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: black;">Fine Jewelry</span></b><b style="mso-bidi-font-weight: normal;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';"> Protection Plan Excludes:</span></b><span style="mso-bidi-font-size: 12.0pt; font-family: 'Arial','sans-serif'; color: black;"></span></p>
              <br />
              

               <div class="test3"><p class="MsoListParagraph" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .35in; mso-add-space: auto; text-indent: -.25in; line-height: normal; mso-list: l4 level1 lfo5; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;"><span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';"> </span></span></span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; margin-left: 7px">Excessive wear or obvious abuse is not covered by the registry</span></p>
               </div>


              <p class="MsoNormal" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: 22.5pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: black;"> </span></p>
              <br />
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: black;">The Fine Jewelry Protection Plan may </span><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';">be renewed every three years.<span style="mso-spacerun: yes;"> </span>Customers near a Daniel’s Jewelers store may have their diamond jewelry checked <b style="mso-bidi-font-weight: normal;"><i style="mso-bidi-font-style: normal;"><span style="text-decoration: underline;">EVERY 3 MONTHS</span></i></b> by a Daniel’s professional.</span></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: black;"> </span></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: red;"> </span></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: black;">Daniel’s offers <span style="text-decoration: underline;">FULL TRADE-IN VALUE PLUS 5%</span> on your fine jewelry. <span style="mso-spacerun: yes;"> </span>The item covered by the Fine Jewelry Protection Plan may be traded in at any time toward the purchase of an item in the same merchandise category whose selling price is at least three times the trade-in amount.<span style="mso-spacerun: yes;"> </span></span></p>
              <br />
              <p class="MsoNormal" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .5in; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: black;"> </span></p>
              <p class="MsoNormal" style="margin-top: 0in; margin-right: 0in; margin-bottom: .0001pt; margin-left: .5in; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><b><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde'; color: white;"> </span></b></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><b><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';">Click here for Registry Service Contract.<span style="mso-spacerun: yes;"> </span></span></b></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><a style="color: blue; word-wrap:break-word;" target="_blank" href="../../../../../assets/AllStatesRegistryTermsandConditions-202201-v2.pdf">https://www.danielsjewelers.com/pdfs/AllStatesRegistryTermsandConditions-202201-v2.pdf</a><span class="pronounce" style="font-size: 10.0pt; mso-bidi-font-size: 11.0pt; font-family: 'Avant Garde';"></span></p>
              <br />
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="font-size: 10.0pt; mso-bidi-font-size: 11.0pt; font-family: 'Avant Garde';"> </span></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><span style="font-size: 9.0pt; mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';">*An original factory defect is a defect that existed at the time that the item was manufactured. Repairs that become necessary because of normal use, wear and tear, or abuse, are not covered under this guarantee.</span></p>
              <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal; mso-layout-grid-align: none; text-autospace: none;"><b><span style="mso-bidi-font-size: 12.0pt; font-family: 'Avant Garde';"> </span></b></p>
           </div>
        </div>
     </div>

  </div>
 
</ng-template>