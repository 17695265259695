import { Component, OnInit, Injectable, PLATFORM_ID, Inject, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../services/product.service';

import { Cart, CartResponse } from '../../classes/cartGraphQl';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../services/account.service';
import { response } from 'express';
import { environment } from '../../../../environments/environment';
import { filter } from 'rxjs/operators';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  searchForm: FormGroup;
  suggest: any = null;
  suggestProducts: any = null;
  inputFocus = false;
  CATALOG_MEDIA_URL = environment.image_base_url;
  public cart: Cart = {};
  public languages = [{
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }];
  public mainMenuToggle = false;
  public cartToggle = false;
  isCheckoutPage = false;
  mincartPopData = null;
  totalItemQty = 0;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService,
    private router: Router,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    public generalService: GeneralService) {
    /*
this.productService.getMiniCart().subscribe( response => {
this.cart = response.cart;
});
this.productService.cartItems.subscribe(response => {
this.productService.setMiniCart(response.data.cart);
});*/
    //this.mincart();
    const self = this;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      document.querySelector('body').classList.remove('sidebar-open');
      document.getElementById('topcart').classList.remove('open');
      document.getElementById('cart-widgets').classList.remove('opencart');
      this.cartToggle = false;
      this.closeMinCartSuccessPopup();
    });

  }

  ngOnInit(): void {
    let searchText = '';
    if (window.location.href.includes('nsearch') && this.getQueryParamFromMalformedURL('q')) {
      searchText = this.getQueryParamFromMalformedURL('q');
    }

    this.searchForm = this.formBuilder.group({
      searchInput: ['', Validators.required],
    });
    this.productService.cartItems?.subscribe(response => {
      if (this.productService.getQuoteId()) {
        this.productService.setMiniCart(response.data.cart);
      }
    });
    this.productService.getMiniCart().subscribe(response => {
      this.totalItemQty = 0;
      this.cart = {};
      if (this.productService.getQuoteId()) {
        this.cart = response.cart;
        this.totalItemQty = this.cart?.items?.length && this.productService.getQuoteId() ? this.cart.items.reduce((a, b) => a + (b.quantity || 0), 0) : 0;
      }
    });

    this.generalService.miniCartPopup.subscribe((data) => {
      if (data) {
        this.mincartPopData = data;
        const top = document.querySelector('#cart-widgets') as HTMLElement;
        window.scroll({
          top: top.offsetTop,
          left: 0,
          behavior: 'smooth'
        });
        this.productService.cartItems?.subscribe(response => {
          if (this.productService.getQuoteId()) {
            this.productService.setMiniCart(response.data.cart);
          }
        });
      } else {
        this.mincartPopData = null;
      }
    });

    this.generalService.isCheckoutPage.subscribe((data) => {
      if (data) {
        this.isCheckoutPage = true;
      } else {
        this.isCheckoutPage = false;
      }
    });
  }
  get compareItemsCount() {
    return JSON.parse(localStorage.compareItems || '[]').length;
  }
  get userLoggedIn() {
    if (localStorage.customerToken) {
      return true;
    }
    return false;
  }

  logOut() {
    this.accountService.logout();
  }

  changeLanguage(code) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code);
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }
  quickSearchFocusOut() {
    const self = this;
    setTimeout(() => {
      self.inputFocus = false;
    }, 1000);
  }
  removeItem(product: any) {
    const totalQty = product.quantity;
    product.quantity = 0;
    this.productService.updateCartItemQuantity(product).subscribe(response => {
      this.productService.cartItems?.subscribe(response => {
        this.productService.setMiniCart(response.data.cart);
      });
    });
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency;
  }

  /*addToggleClass() {
    document.querySelector('.toggle-nav').classList.add('lowzindex');
  }
  removeToggleClass() {
    document.querySelector('.toggle-nav').classList.remove('lowzindex');
  }*/
  public openSearch() {
    document.getElementById('topsearch').classList.add('open');
    // this.addToggleClass();
    this.closeMinCartSuccessPopup();
  }

  public closeSearch() {
    this.showHideSearchBar();
    //  this.removeToggleClass();
    this.suggest = null;
    this.suggestProducts = null;
    this.searchForm.reset();
  }
  public openAccount() {
    this.closeMinCartSuccessPopup();
    document.getElementById('topaccount').classList.add('open');
    // this.addToggleClass();

  }
  public closeAccount() {
    document.getElementById('topaccount').classList.remove('open');
    //  this.removeToggleClass();
  }


  opencart() {
    // this.addToggleClass();
    this.cartToggle = !this.cartToggle;
    (document.querySelector('body').classList.contains('sidebar-open')) ? document.querySelector('body').classList.remove('sidebar-open') : document.querySelector('body').classList.add('sidebar-open');
    this.closeMinCartSuccessPopup();
  }

  closeCart() {
    document.getElementById('topcart').classList.remove('open');
    document.getElementById('cart-widgets').classList.remove('opencart');
    document.querySelector('body').classList.remove('sidebar-open');
    this.cartToggle = !this.cartToggle;
    // this.removeToggleClass();
  }
  quickSearchSuggest(event) {
    const searchString = event.target.value;
    if (searchString != null && searchString.trim().length > 2) {
      this.productService.searchAutoComplete(searchString).subscribe(
        response => {
          let json = response.replace('AutoComplete(', '');
          json = json.substring(0, json.length - 1);
          const searchResult = JSON.parse(json);
          this.suggest = searchResult.terms?.r;
          this.suggestProducts = searchResult.products.r;
        }
      );
    }

  }

  onSubmit() {

    if (this.searchForm.invalid) {
      return;
    }
    let value = this.searchForm.controls.searchInput.value;
    value = value.replace(/ /g, '+')
    document.location.href = `nsearch?q=${value}`;
    
    // this.router.navigate([`/category/search`], { queryParams: { searchstring: this.searchForm.controls.searchInput.value} });
    this.closeSearch();
  }

  searchSuggestion(suggestion) {
    console.log('suggestion',suggestion);
    // this.router.navigate([`/category/search`], { queryParams: { searchstring: suggestion} });
    let value = suggestion
    value = value.toString().replace(/ /g, '+');
    // this.router.navigate([`/category/search`], { queryParams: { searchstring: suggestion} });
    document.location.href = `/nsearch?q=${value}`;
    this.closeSearch();
  }


  showHideSearchBar() {
    this.mainMenuToggle = !this.mainMenuToggle;
  }

  getProductLink(url) {
    /* const attributeSetId =  product.attribute_set_id[0];
     if (attributeSetId == 12) {
       return ['/design-your-own/', product.sku];
     }else {
       return ['/product/', product.url_key];
     }*/
    const productURL = url.replace(/^.*\/\/[^\/]+/, '').substring();
    return [productURL];
  }

  searchSuggestionLabel(label) {
    const searchString = this.searchForm.controls.searchInput.value;
    return label.replaceAll(new RegExp(searchString, 'gi'), `<span class="highlight">${searchString}</span>`);
  }

  closeMinCartSuccessPopup() {
    this.generalService.removeMiniCartSuccessPopup();
  }

  getQueryParamFromMalformedURL(name) {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(decodeURIComponent(window.location.href)); // or window.location.href
    if (!results) {
      return '';
    }
    return results[1] || '';
  }
}
