import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';


export interface Child3 {
	id: number;
	level: number;
	name: string;
	path: string;
	url_path: string;
	url_key: string;
	image: string;
	parent_id: any;
	category_image_addtional: string;
	category_path: string;
	nextopia_category_refinement: string;
}

export interface Child2 {
	id: number;
	level: number;
	name: string;
	path: string;
	url_path: string;
	url_key: string;
	image: string;
	parent_id: any;
	category_image_addtional: string;
	category_path: string;
	nextopia_category_refinement: string;
	children: Child3[];
}

export interface Child {
	id: number;
	level: number;
	name: string;
	path: string;
	url_path: string;
	url_key: string;
	image: string;
	parent_id: any;
	category_image_addtional: string;
	category_path: string;
	nextopia_category_refinement: string;
	megamenu_image: string;
	children: Child2[];
}

export interface CategoryList {
	children_count: string;
	children: Child[];
}

export interface Data {
	categoryList: CategoryList[];
}

export interface MegaMenu {
	data: Data;
}


@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor(
		private http: HttpClient
	) { }

	public screenWidth: any;
	public mainMenuToggle = false;

	BASE_URL = environment.BASE_URL;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth =  window.innerWidth;
	}

	getMegaMenuDetails() {
// tslint:disable-next-line:indent
  let params = new HttpParams();
  params = params.append('query', `{
	categoryList(filters: {ids: {eq: "2"}}) {
		children_count
		children {
		id
		level
		name
		description
		path
		url_path
		url_key
		image
		parent_id
		meta_title
		meta_description
		category_image_addtional
		category_path
		nextopia_category_refinement
		megamenu_image
		children {
		id
		level
		name
		description
		path
		url_path
		url_key
		image
		parent_id
		meta_title
		meta_description
		category_image_addtional
		category_path
		nextopia_category_refinement
		children {
		id
		level
		name
		description
		path
		url_path
		url_key
		image
		parent_id
		meta_title
		meta_description
		category_image_addtional
		category_path
		nextopia_category_refinement
		}
		}
		}
		}
	  }`);

	 return this.http.get(`${this.BASE_URL}graphql`, {params})
	  .pipe(
		  map( res => {
			if (res) {
				return res;
			}
		  })
		);
	   }


}
